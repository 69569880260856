import useLang from '@/hooks/useLang';
import { hideScrollbarY } from '@/utils/cssStyles';
import { Box, Typography } from '@mui/material';
import { StatisticsTabs } from './Tabs';

interface Props {}

export const TabStatistics = (props: Props) => {
  const { __, isEn } = useLang();
  return (
    <Box
      height={'calc(100vh - 136px)'}
      id="invite-scroll-container"
      pb={2}
      sx={{
        ...hideScrollbarY,
      }}
    >
      <Typography
        py={3}
        px={2}
        variant="subtitle2"
        fontStyle="italic"
        fontWeight={700}
        fontSize={isEn ? 16 : 14}
        lineHeight={{ md: 1.25 }}
      >
        {__('The following data is updated every 10 minutes')}
        {isEn ? null : (
          <>
            <br />
            Obs.: Todos os jogos de pesca e o provedor Evo Play não participarão
            deste evento.
          </>
        )}
      </Typography>
      <StatisticsTabs />
    </Box>
  );
};
