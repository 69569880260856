import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton } from '@mui/material';
import { alpha } from '@mui/material/styles';
import {
  SnackbarProvider as NotistackProvider,
  ProviderContext,
  VariantType,
  useSnackbar,
} from 'notistack';
// import { useSettingsContext } from '../settings';
import Iconify, { IconifyProps } from '../iconify';

import ErrorIcon from '@/assets/icons/snake_fail.png';
import SuccessIcon from '@/assets/icons/snake_success.png';
import WarningIcon from '@/assets/icons/snake_warning.png';

const getBgColor = (variant: VariantType) => {
  switch (variant) {
    case 'success':
      return { backgroundColor: '#3CD75B' };
    case 'warning':
      return { backgroundColor: '#FFB92E' };
    case 'error':
      return { backgroundColor: '#FE493D' };
    default:
      return void 0;
  }
};

type Props = {
  children: React.ReactNode;
};

export default function AppSnackbarProvider({ children }: Props) {
  return (
    <NotistackProvider
      dense
      maxSnack={5}
      preventDuplicate
      disableWindowBlurListener
      autoHideDuration={1800}
      // TransitionComponent={isRTL ? Collapse : undefined}
      variant="success" // Set default variant
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      iconVariant={{
        success: (
          <img
            src={SuccessIcon}
            alt=""
            width={20}
            height={20}
            style={{ marginInlineEnd: 8 }}
          />
        ),
        warning: (
          <img
            src={WarningIcon}
            alt=""
            width={20}
            height={20}
            style={{ marginInlineEnd: 8 }}
          />
        ),
        error: (
          <img
            src={ErrorIcon}
            alt=""
            width={20}
            height={20}
            style={{ marginInlineEnd: 8 }}
          />
        ),
      }}
    >
      {children}
    </NotistackProvider>
  );
}

let useSnackbarRef: ProviderContext;
export const SnackbarUtilsConfigurator: React.FC = () => {
  useSnackbarRef = useSnackbar();
  return null;
};
export const SnackbarUtils = {
  success(msg: string, onClose?: () => void, duration: number = 1800) {
    this.toast(msg, 'success', onClose, duration);
  },
  warning(msg: string, onClose?: () => void, duration: number = 1800) {
    this.toast(msg, 'warning', onClose, duration);
  },
  info(msg: string, onClose?: () => void, duration: number = 1800) {
    this.toast(msg, 'info', onClose, duration);
  },
  error(msg: string, antoHide?: number | null, onClose?: () => void) {
    this.toast(msg, 'error', onClose, antoHide === null ? null : 1800);
  },
  toast(
    msg: string,
    variant: VariantType = 'default',
    onClose?: () => void,
    antoHide?: number | null,
  ) {
    useSnackbarRef.enqueueSnackbar(msg, {
      variant,
      onClose,
      anchorOrigin: { vertical: 'top', horizontal: 'center' },
      action: (key) => {
        return (
          <IconButton
            size="small"
            onClick={() => useSnackbarRef.closeSnackbar(key)}
          >
            <CloseIcon sx={{ fontSize: 16, color: 'white' }} />
          </IconButton>
        );
      },
      autoHideDuration: antoHide,
      disableWindowBlurListener: true,
      style: {
        backgroundColor: '#313d4d',
      },
    });
  },
};

type SnackbarIconProps = {
  icon: IconifyProps;
  color: 'info' | 'success' | 'warning' | 'error';
};

function SnackbarIcon({ icon, color }: SnackbarIconProps) {
  return (
    <Box
      component="span"
      sx={{
        mr: 1.5,
        width: 40,
        height: 40,
        display: 'flex',
        borderRadius: 1.5,
        alignItems: 'center',
        justifyContent: 'center',
        color: `${color}.main`,
        bgcolor: (theme) => alpha(theme.palette[color].main, 0.16),
      }}
    >
      <Iconify icon={icon} width={24} />
    </Box>
  );
}
