import { SnackbarUtils } from '@/components/snackbar/SnackbarProvider';
import { StorageKeys } from '@/constants/keys';
import useLang from '@/hooks/useLang';
import { homeTab as homeTabState } from '@/stores/config';
import { Box, Typography } from '@mui/material';
import Fingerprint2 from 'fingerprintjs2';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

// 新增device-id缓存逻辑 ==> 确保每次请求有device-id
const deviceID = localStorage.getItem(StorageKeys.deviceId);
const rememberIP = localStorage.getItem('rememberIP') || '';
const requestTime = localStorage.getItem('requestTime') || '';
let deviceprintT: any = deviceID || undefined;

export const TestDeviceIdPage = () => {
  const [homeTab, setHomeTab] = useRecoilState(homeTabState);
  const navigate = useNavigate();
  const search = new URLSearchParams(window.location.search);
  const code = search.get('code') || '';
  const { __ } = useLang();

  const handleCopy = async (textu: string) => {
    console.log('text:', textu);
    const clipboard = navigator.clipboard || {
      writeText: (text) => {
        const input = document.createElement('input');
        input.value = text;
        document.body.appendChild(input);
        input.select();
        document.execCommand('copy');
        document.body.removeChild(input);
      },
    };
    if (clipboard) {
      clipboard.writeText(textu);
      SnackbarUtils.success(__('Copy to clipboard'));
    }
  };

  const handleReceive = async () => {
    console.log('触发指纹');
    setFingerprint();
  };

  const setFingerprint = () => {
    // deviceprint已存在缓存，则返回

    Fingerprint2.get(
      {
        // preprocessor: null,
        audio: {
          timeout: 1000,
          // On iOS 11, audio context can only be used in response to user interaction.
          // We require users to explicitly enable audio fingerprinting on iOS 11.
          // See https://stackoverflow.com/questions/46363048/onaudioprocess-not-called-on-ios11#46534088
          excludeIOS11: true,
        },
        fonts: {
          swfContainerId: 'fingerprintjs2',
          swfPath: 'flash/compiled/FontList.swf',
          userDefinedFonts: [],
          extendedJsFonts: true,
        },
        screen: {
          // To ensure consistent fingerprints when users rotate their mobile devices
          detectScreenOrientation: true,
        },
        plugins: {
          sortPluginsFor: [/palemoon/i],
          excludeIE: false,
        },
        extraComponents: [],
        excludes: {
          // Unreliable on Windows, see https://github.com/Valve/fingerprintjs2/issues/375
          enumerateDevices: true,
          // devicePixelRatio depends on browser zoom, and it's impossible to detect browser zoom
          pixelRatio: true,
          // DNT depends on incognito mode for some browsers (Chrome) and it's impossible to detect incognito mode
          doNotTrack: true,
          // uses js fonts already
          fontsFlash: true,

          // #### Browser independent components

          userAgent: true,
          //no (most of the time)
          language: true,
          // no (but not supported by IE)
          hardwareConcurrency: true,

          sessionStorage: true,
          localStorage: true,
          indexedDb: true,
          addBehavior: true,
          openDatabase: true,
          plugins: true,
          adBlock: true,
          fonts: true,
          audio: true,

          // exclude screen
          screenResolution: true,
          availableScreenResolution: true,

          // (most of the time)
          //'canvas': true,
          //'webgl': true,
          //'fonts',
        },
        NOT_AVAILABLE: 'not available',
        ERROR: 'error',
        EXCLUDED: 'excluded',
      },
      (components) => {
        components.push({ key: 'ip', value: rememberIP });
        components.push({ key: 'requestTime', value: requestTime });

        var values = components.map((component) => {
          return component.value;
        });
        deviceprintT = Fingerprint2.x64hash128(values.join(''), 31);
      },
    );
  };

  return (
    <Box
      sx={{
        width: '100vw',
        mx: 'auto',
        height: '100vh',
        bgcolor: '#7647EB',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          width: '100vw',
          height: '133.33vw',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      ></Box>

      <Box
        sx={{
          height: '174px',
          width: '343px',

          mt: '-164px',
          px: 4,
        }}
      >
        <Box
          sx={{
            background: '#E4A833',

            height: '48px',
            borderRadius: 999,
            maxWidth: '350px',
            mx: 'auto',
            cursor: 'pointer',
            mt: '16px',
          }}
        >
          <Box
            sx={{
              background: 'linear-gradient(90deg, #FFE767 0%, #EA7D27 100%)',
              height: '44px',
              borderRadius: 999,
              textAlign: 'center',
              cursor: 'pointer',
            }}
            onClick={handleReceive}
          >
            <Typography variant="subtitle1" sx={{ lineHeight: '44px' }}>
              {__('Go To Redeem')}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box width={'100vw'} px={'16px'} mt={'16px'}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
          }}
        >
          <Box
            sx={{
              width: '4px',
              height: '4px',
              borderRadius: '2px',
              mx: '4px',
              mt: '6px',
              bgcolor: 'text.secondary',
            }}
          />
          <Typography variant="body2" fontWeight={500} color={'text.secondary'}>
            {deviceprintT}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
