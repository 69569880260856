import BG from '@/assets/images/stop-service-bg.png';
import { noticeListState } from '@/stores/config';
import { Backdrop, Box, Button, Typography } from '@mui/material';
import { useRecoilState } from 'recoil';
import Center from '../center';

export const StopServiceNotificaiton = () => {
  // const isStopService = useRecoilValue(isStopServiceState);
  const [noticeList, setNoticeList] = useRecoilState(noticeListState);

  const isStopService = !!noticeList?.at(0);

  return (
    <Box>
      <Backdrop open={isStopService} sx={{ zIndex: 9999 }}>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Box position="relative">
            <img src={BG} alt="" width={320} height={'auto'} />
            <Box position="absolute" top={232} left={0} bottom={0} right={0}>
              <Typography
                textAlign="center"
                fontSize={28}
                fontWeight={700}
                lineHeight={1}
                px={2}
                className="futura-font"
                color="primary.main"
              >
                Anúncio de descontinuação do serviço！
              </Typography>
              <Box minHeight={120} mt={2}>
                {noticeList
                  ?.at(0)
                  ?.content?.split('\\n')
                  .map((item, index) => {
                    return (
                      <Typography
                        px={2.5}
                        key={`${index}-${item}`}
                        fontSize={16}
                        lineHeight={1.25}
                      >
                        {item}
                      </Typography>
                    );
                  })}
              </Box>
              <Center>
                <Button
                  variant="contained"
                  sx={{
                    mt: 3,
                    borderRadius: 999,
                    width: '160px',
                    height: '48px',
                    mx: 'auto',
                  }}
                  onClick={() => {
                    setNoticeList([]);
                  }}
                >
                  Got it
                </Button>
              </Center>
            </Box>
          </Box>
        </Box>
      </Backdrop>
    </Box>
  );
};
