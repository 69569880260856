import { Box, Button, Stack, Typography } from '@mui/material';

import useLang from '@/hooks/useLang';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
interface Props {
  title: string;
  desc?: string;
  icon?: string;
  children: React.ReactNode;
  onClickMore?: () => void;
  onPrev?: () => void;
  onNext?: () => void;
  hasNext?: boolean;
  hasPrev?: boolean;
  hideViewAll?: boolean;
  total?: number;
}

const HomeSectionContainer: React.FC<Props> = (props: Props) => {
  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        pb={1.5}
      >
        <Stack direction="row" alignItems="center">
          {props?.icon ? (
            <img
              src={props.icon}
              alt=""
              style={{
                width: '16px',
                height: '16px',
                marginRight: '4px',
              }}
            />
          ) : null}

          <Typography
            variant="subtitle2"
            className="home-game-list-font"
            sx={{
              textTransform: 'capitalize',
              fontWeight: 700,
              fontSize: 20,
              fontStyle: 'italic',
              lineHeight: 1,
            }}
          >
            {props.title}
          </Typography>
        </Stack>

        <Stack direction="row" alignItems="center" spacing={1}>
          {props.hideViewAll ? null : props.total !== void 0 ? (
            <TotalButton total={props.total} onClick={props.onClickMore} />
          ) : (
            <ViewAllButton onClick={props.onClickMore} />
          )}
          {!!props.onPrev && (
            <Button
              size="small"
              variant="outlined"
              aria-label="prev"
              onClick={props.onPrev}
              sx={{
                width: 25,
                height: 25,
                minWidth: 25,
                borderRadius: '50%',
                borderColor: '#35314B !important',
                color: props.hasPrev ? '#fff' : '#8B899C',
              }}
            >
              <ChevronRightRoundedIcon
                fontSize="small"
                sx={{ transform: 'rotate(180deg)' }}
              />
            </Button>
          )}
          {!!props.onNext && (
            <Button
              size="small"
              variant="outlined"
              aria-label="prev"
              onClick={props.onNext}
              sx={{
                width: 25,
                height: 25,
                minWidth: 25,
                borderRadius: '50%',
                borderColor: '#35314B !important',
                color: props.hasNext ? '#fff' : '#8B899C',
              }}
            >
              <ChevronRightRoundedIcon fontSize="small" />
            </Button>
          )}
        </Stack>
      </Stack>
      {props.desc ? (
        <Typography color="text.secondary" variant="body2" fontWeight={500}>
          {props.desc}
        </Typography>
      ) : null}
      {props.children}
    </Box>
  );
};

export default HomeSectionContainer;

const TotalButton = (props: { total: number; onClick?: () => void }) => {
  return (
    <Button
      sx={{
        height: '28px',
        minWidth: '64px',
        borderRadius: '4px',
        bgcolor: 'background.neutral',
        '&:hover': {
          bgcolor: 'background.neutral',
        },
        color: 'text.primary',
        fontSize: 12,
        fontWeight: 700,
        lineHeight: 1,
        pl: 1,
        pr: 0,
      }}
      variant="text"
      size="small"
      onClick={props.onClick}
    >
      {props.total}
      <ChevronRightRoundedIcon sx={{ width: '18px', height: '18px' }} />
    </Button>
  );
};

const ViewAllButton = (props: { onClick?: () => void }) => {
  const { __ } = useLang();
  return (
    <Button
      sx={{
        height: 25,
        // borderColor: '#323D4D !important',
        borderRadius: '4px',
        fontSize: 12,
        fontWeight: 400,
        minWidth: 0,
        px: 1,
        fontStyle: 'italic',
      }}
      variant="text"
      size="small"
      onClick={props.onClick}
    >
      {__('View All')}
      <ChevronRightRoundedIcon fontSize="small" />
    </Button>
  );
};
