import { allGamesMap } from '@/stores/config';
import { IGameItem, IIndexCategory } from '@/types/config';
import { hideScrollbarX } from '@/utils/cssStyles';
import { Box, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import HomeSectionContainer from './SectionContainer';

import IMAGE_P_1 from '@/assets/plateform/p-1.png';
import IMAGE_P_2 from '@/assets/plateform/p-2.png';
import IMAGE_P_3 from '@/assets/plateform/p-3.png';
import IMAGE_P_4 from '@/assets/plateform/p-4.png';
import IMAGE_P_5 from '@/assets/plateform/p-5.png';
import IMAGE_P_6 from '@/assets/plateform/p-6.png';

const plateformLogoList = [
  { id: 1, pic: IMAGE_P_1 },
  { id: 2, pic: IMAGE_P_2 },
  { id: 3, pic: IMAGE_P_3 },
  { id: 4, pic: IMAGE_P_4 },
  { id: 5, pic: IMAGE_P_5 },
  { id: 6, pic: IMAGE_P_6 },
];

interface Props {
  list: IIndexCategory[];
}

export const HomeHavingAttention = (props: Props) => {
  if (!props.list || props.list.length === 0) {
    return null;
  }

  return (
    <>
      {props.list.map((item, idx) => {
        return (
          <Box key={`${idx}-${item.title}`}>
            <HomeSectionContainer hideViewAll title={item.title}>
              <AttentionBoxList ids={item.ids} />
            </HomeSectionContainer>
          </Box>
        );
      })}
    </>
  );
};

const AttentionBoxList = (props: { ids: number[] }) => {
  const allGamesMapValue = useRecoilValue(allGamesMap);

  const gamesList = useMemo(() => {
    const list: IGameItem[][] = [];
    props.ids.forEach((id, idx) => {
      if (allGamesMapValue[id]) {
        const x = Math.floor(idx / 3);
        const y = idx % 3;
        list[x] = list[x] || [];
        list[x][y] = allGamesMapValue[id];
      }
    });
    return list;
  }, [allGamesMapValue, props.ids]);

  return (
    <Box
      sx={{
        ...hideScrollbarX,
        display: 'flex',
      }}
    >
      {gamesList.map((games, idx) => {
        return (
          <Box key={idx} mr={1}>
            {games.map((game, jdx) => {
              return (
                <AttentionBox
                  key={game.id}
                  idx={idx * 3 + jdx + 1}
                  cover={game.picPure ?? game.pic}
                  gameId={+game.id}
                  title={game.game_name}
                  categoryId={+game.category_id}
                />
              );
            })}
          </Box>
        );
      })}
    </Box>
  );
};

const AttentionBox = (props: {
  categoryId: number;
  idx: number;
  cover: string;
  gameId: number;
  title: string;
}) => {
  const navigate = useNavigate();

  const plateformPic = plateformLogoList.find(
    (item) => +item.id === +props.categoryId,
  )?.pic;

  const onGoProvider = () => {
    navigate(`/provider?id=${props.categoryId}`);
  };

  const onGoGameDetail = () => {
    navigate(`/game/info?id=${props.gameId}`);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        mb: 1.5,
      }}
    >
      <Box sx={{ fontSize: 16, fontWeight: 800 }}>{props.idx}</Box>
      <Box
        sx={{
          width: 72,
          height: 94,
          overflow: 'hidden',
          borderRadius: '8px',
          marginRight: '8px',
          mx: 1,
          cursor: 'pointer',
        }}
        onClick={onGoGameDetail}
      >
        <img src={props.cover} alt="" width={72} height={94} />
      </Box>
      <Box sx={{ width: '100px', minWidth: '100px' }}>
        <Typography variant="subtitle2" fontWeight={800} lineHeight={1}>
          {props.title}
        </Typography>
        <Box pr="20px">
          <Box
            sx={{
              // bgcolor: 'background.neutral',
              overflow: 'hidden',
              borderRadius: 1,
              mt: 1,
              cursor: 'pointer',
            }}
            onClick={onGoProvider}
          >
            <img src={plateformPic} alt="" width={80} height={30} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
