import useLang from '@/hooks/useLang';
import useResponsive from '@/hooks/useResponsive';
import { hideScrollbarX } from '@/utils/cssStyles';
import { Box } from '@mui/material';
interface Props {
  tab: string;
  tabs: string[];
  onClickTab: (tab: string, index: number) => void;
}

export const InviteTabButtons = (props: Props) => {
  const isMobile = useResponsive('down', 'md');
  const { __ } = useLang();
  const handleClick = (tab: string, index: number) => () => {
    props.onClickTab(tab, index);
  };

  return (
    <Box
      pt={isMobile ? 0 : 2}
      sx={{
        position: 'sticky',
        top: 0,
        left: 0,
        width: '100%',
      }}
    >
      <Box
        sx={{
          ...hideScrollbarX,
        }}
      >
        <Box display="flex">
          {props.tabs.map((item, index) => {
            const isActive = props.tab === item;
            return (
              <Box
                key={item}
                onClick={handleClick(item, index)}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flex: 1,
                  height: 28,
                  cursor: 'pointer',
                }}
              >
                <Box>
                  <Box
                    sx={{
                      fontSize: '16px',
                      lineHeight: 1,
                      fontWeight: isActive ? 700 : 500,
                    }}
                  >
                    {__(`tab${item}`)}
                  </Box>
                  <Box
                    sx={{
                      mx: 0.5,
                      height: '4px',
                      width: 'calc(100% - 8px)',
                      bgcolor: isActive ? 'primary.main' : 'transparent',
                      borderRadius: '2px',
                      mt: 1,
                    }}
                  ></Box>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
