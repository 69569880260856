import { SnackbarUtils } from '@/components/snackbar/SnackbarProvider';
import { INVITE_CODE_KEY } from '@/constants';
import useAuth from '@/hooks/useAuth';
import useBoolean from '@/hooks/useBoolean';
import useLang from '@/hooks/useLang';
import services from '@/services';
import {
  cacheIPTimeState,
  failAccountState,
  isOpenLRModalState,
  isOpenRegisterModalState,
} from '@/stores/auth';
import { ColorfulBg } from '@/styles';
import { customColor } from '@/theme/customColor';
import { SigninType } from '@/types';
import { getFingerprint2, getFingerprintReg } from '@/utils/FingerprintUtil';
import { LoadingButton } from '@mui/lab';
import { Box, Checkbox, OutlinedInput, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import PasswordInput from '../login/PasswordInput';
import PhoneInput from '../login/PhoneInput';
interface Props {
  onSuccess?: () => void;
  type: SigninType;
  displayType: 'dialog' | 'page';
}

const RegisterForm: React.FC<Props> = (props: Props) => {
  const setIsOpenRegisterModal = useSetRecoilState(isOpenRegisterModalState);
  const setIsOpenLRModal = useSetRecoilState(isOpenLRModalState);
  const [failAccount, setFailAccountState] = useRecoilState(failAccountState);
  const [cacheIPTime, setCacheIPTime] = useRecoilState(cacheIPTimeState);

  const [password, setPassword] = useState<string>('');
  const [passwordAgain, setPasswordAgain] = useState<string>('');
  const [phone, setPhone] = useState<string>(failAccount || '');
  const [email, setEmail] = useState<string>('');
  const [areaCode, setAreaCode] = useState<number>(55);
  const [inviteCode, setInviteCode] = useState<string>(
    sessionStorage.getItem(INVITE_CODE_KEY) || '',
  );
  const [rememberAp, setRememberAp] = useState<boolean>(true);
  const [isPrivacy, setIsPrivacy] = useState<boolean>(true);
  const [loading, setLoading] = useBoolean();
  const { getUserInfo, getWallet } = useAuth();
  const navigate = useNavigate();
  const search = new URLSearchParams(window.location.search);
  const guid = search.get('guid');
  const id = search.get('id');
  const { __ } = useLang();

  const isPhone = props.type === SigninType.Phone;
  const isAvaliable =
    // !!code && && !!passwordAgain
    !!password && (isPhone ? !!phone : !!email) && isPrivacy;

  const postLoginRequest = async (params: any) => {
    return await services.auth.login(params);
  };

  const onPrivacy = () => {
    window.open('https://www.br-baby.com/agreement.html', '_blank');
  };

  const handleSubmit = async () => {
    const cacheIp = localStorage.getItem('rememberIP') || '';
    const cacheTime = localStorage.getItem('requestTime') || '';
    if (loading) {
      return;
    }
    if (password !== passwordAgain) {
      SnackbarUtils.error(__('密码不一致'));
      return;
    }
    let params: any = {
      password,
      requestType: 2,
      // code,
    };

    if (isPhone) {
      params['phone'] = phone;
      params['type'] = 2;
    } else {
      params['email'] = email;
      params['type'] = 1;
    }
    if (guid && id) {
      params['bind_id'] = id;
      params['bind_code'] = guid;
    }

    // const inviteCode = sessionStorage.getItem(INVITE_CODE_KEY);
    if (inviteCode) {
      params['recommender'] = inviteCode;
    }

    params['cacheIp'] = cacheIp || '';
    params['cacheTime'] = cacheTime || '';

    let regDeviceId = await getFingerprintReg(
      cacheIPTime?.rememberIP,
      cacheIPTime?.requestTime,
    );
    params['regDeviceId'] = regDeviceId || '';
    await getFingerprint2();

    setLoading.on();
    try {
      const res = await postLoginRequest(params);
      const token = res.data?.data?.token;
      if (token) {
        localStorage.setItem('access_token', token);
        // 记住账号密码
        if (rememberAp) {
          delete params.recommender;
          localStorage.setItem('rememberAccount', JSON.stringify(params));
        }
        props.onSuccess?.();
        getUserInfo();
        getWallet();
        navigate('/');
        setIsOpenRegisterModal(false);
        setIsOpenLRModal(false);
        setFailAccountState('');
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading.off();
    }
  };

  const handleSendCode = () => {
    if (isPhone) {
      return services.auth.sendSMS({ phone: `+55${phone}`, type: 1 });
    } else {
      return services.auth.sendEmail({ email, type: 1 });
    }
  };

  const AccountInput = {
    [SigninType.Email]: (
      <OutlinedInput
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder={__('Please enter email')}
      />
    ),
    [SigninType.Phone]: (
      <PhoneInput
        phone={phone}
        areaCode={areaCode}
        onAreaCodeChange={(v) => setAreaCode(v)}
        onChangePhone={(v) => setPhone(v)}
      />
    ),
  };

  return (
    <Box>
      <Stack spacing={{ xs: 1.5, md: 2 }}>
        {AccountInput[props.type]}
        {/* <CodeInput
          code={code}
          onChange={(v) => setCode(v)}
          onSendSMS={handleSendCode}
        /> */}
        <PasswordInput
          password={password}
          onChangePassword={(pwd) => setPassword(pwd)}
          placeholder={__('Enter new password')}
        />
        {/* <OutlinedInput
          value={inviteCode}
          onChange={(e) => setInviteCode(e.target.value)}
          size="medium"
          type="number"
          placeholder={__('Invitation Code (Optional)')}
        /> */}
        <PasswordInput
          password={passwordAgain}
          onChangePassword={(pwd) => setPasswordAgain(pwd)}
          placeholder={__('Enter new password Again')}
        />
      </Stack>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          mt: '40px',
        }}
      >
        <Checkbox
          checked={isPrivacy}
          sx={{ '& .MuiSvgIcon-root': { fontSize: 16 } }}
          onChange={(e) => {
            setIsPrivacy(e.target.checked);
          }}
        />
        <Typography
          variant="body2"
          sx={{ span: { color: 'primary.main' } }}
          color="text.secondary"
        >
          {__('pricayTip')}{' '}
          <span
            onClick={() => {
              onPrivacy();
            }}
          >
            {__('pricayLink')}
          </span>
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Checkbox
          checked={rememberAp}
          sx={{ '& .MuiSvgIcon-root': { fontSize: 16 } }}
          onChange={(e) => {
            setRememberAp(e.target.checked);
          }}
        />
        <Typography variant="body2" color="text.secondary">
          {__('Remember account password')}
        </Typography>
      </Box>
      <LoadingButton
        variant="contained"
        fullWidth
        sx={{
          boxShadow: 'none !important',
          mt: 2,
          borderRadius: '8px',
        }}
        style={{
          backgroundColor: isAvaliable
            ? ColorfulBg()
            : customColor.disable_button_bg,
        }}
        loading={loading}
        onClick={handleSubmit}
        disabled={!isAvaliable}
      >
        {__('Register And Sign In')}
      </LoadingButton>
      {/* <Typography
        variant="body2"
        mt={{ xs: 1.5, md: 3 }}
        textAlign="center"
        sx={{ span: { color: 'info.main' } }}
        color="text.disabled"
      >
        This site is subject to recaptcha and Google <span>
          Privacy Policy
        </span>{' '}
        and <span>Terms Of Service</span> apply.
      </Typography> */}
      {/* Already have an account? Log in now */}
      {/* <Typography
        variant="body2"
        textAlign="center"
        mt={{ xs: 4, md: 3 }}
        sx={{
          span: { color: 'primary.main', ml: 1 },
          cursor: 'pointer',
        }}
        onClick={() => {
          if (props.displayType === 'dialog') {
            setIsOpenSigninModal(true);
            setIsOpenRegisterModal(false);
          } else {
            navigate('/signin');
          }
        }}
      >
        {__('Already have an account?')}
        <span>{__('Log in now')}</span>
      </Typography> */}
    </Box>
  );
};

export default RegisterForm;
