import ICON_MONEY_PAY from '@/assets/icons/ic_moneyPay.png';
import useAuth from '@/hooks/useAuth';
import useLang from '@/hooks/useLang';
import { weekCardDto } from '@/hooks/useWeekCardBonus';

import services from '@/services';
import { payChannelDataState } from '@/stores/config';
import { ColorfulBg } from '@/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { LoadingButton } from '@mui/lab';
import { Box, Checkbox, Stack, Typography } from '@mui/material';
import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

interface Props {
  data: weekCardDto | null;
  onPayCb: (url: string) => void;
}

/**
 * 提现弹窗内容
 *
 * @param {Props} props
 * @return {*}
 */
const WeekCardFailDrawerConten = (props: Props) => {
  const { __ } = useLang();
  const [payList, setPayList] = useState<any[]>([]);
  const [isAvaliable, setIsAvaliable] = useState<boolean>(true);
  const [depositLoading, setDepositLoading] = useState<boolean>(false);
  const [money, setMoney] = useState<number>(0);
  const { wallet, isLogin, openLogin } = useAuth();

  const [payChannelData, setPayChannelData] =
    useRecoilState(payChannelDataState);

  useEffect(() => {
    const { data } = props;
    let activityRule = data?.activityRulesList[0] || { ruleTargetAmt: 0 };
    let moneyT = activityRule?.ruleTargetAmt;
    let wMoney = wallet?.money || 0;
    let payListTmp = [
      {
        name: __('Balance payment'),
        type: 1,
        icon: ICON_MONEY_PAY,
        isSel: data?.payType === 2 ? true : false,
        pay_typeid: 'balance',
      },
    ];
    let onlinepayAry = [];
    if (payChannelData?.onlinepay_list) {
      onlinepayAry = Object.keys(payChannelData.onlinepay_list).map(
        (key) => payChannelData.onlinepay_list[key],
      );
    }
    onlinepayAry = onlinepayAry.map((payT, index) => {
      let payTmp = {
        ...payT,
        name: payT.title,
        type: 2,
        icon: payT.pic,
        min_money: payT.min_money,
        isSel: index === 0 && data?.payType === 1 ? true : false,
        pay_typeid: payT.pay_typeid,
      };
      return payTmp;
    });

    if (data?.payType === 2) {
      payListTmp = [...payListTmp, ...onlinepayAry];
    } else {
      payListTmp = [...onlinepayAry];
    }
    setMoney(moneyT);
    setPayList(payListTmp);
    setIsAvaliable(data?.payType === 2 ? wMoney >= moneyT : true);
  }, [props.data]);

  const onSelect = (item: any) => {
    let payListTmp = cloneDeep(payList);
    let selType = 0;
    payListTmp.forEach((payT) => {
      if (payT.pay_typeid === item.pay_typeid) {
        payT.isSel = true;
        selType = payT.type;
      } else {
        payT.isSel = false;
      }
    });
    if (selType !== 1) {
      setIsAvaliable(true);
    } else {
      const { data } = props;
      let activityRule = data?.activityRulesList[0] || { ruleTargetAmt: 0 };
      let moneyT = activityRule?.ruleTargetAmt;
      let wMoney = wallet?.money || 0;
      setIsAvaliable(wMoney >= moneyT);
    }
    setPayList(payListTmp);
  };

  const handleDeposit = async () => {
    let item = props.data;
    if (!isLogin) {
      openLogin();
      return;
    }
    if (depositLoading) {
      return;
    }

    let params: any = {
      activityId: item?.id,
    };
    let selPay = payList.find((pay) => pay.isSel);
    if (!selPay) {
      return;
    }
    if (selPay.type === 2) {
      params.channelId = selPay.id;
    }
    if (!params) {
      return;
    }

    setDepositLoading(true);
    try {
      const { data } = await services.wallet.buyWeekCard(params);
      console.log('data：', data);
      props.onPayCb && props.onPayCb(data?.data || '');
    } catch (error) {
      console.log(error);
    } finally {
      setDepositLoading(false);
    }
  };

  return (
    <Box sx={{ width: '100%', pb: { xs: 2, md: 0 } }} pb={'8px'}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h1" color={'text.primary'} mb={'4px'}>
          <span style={{ fontSize: 16 }}>{'R$'}</span>
          {`${money}`}
        </Typography>
        <Typography variant="body1" color={'text.secondary'} mb={'16px'}>
          {__('Need to pay')}
        </Typography>
      </Box>
      <Box px={2.5} pt={2.5} pb={4}>
        <Stack
          sx={{
            borderRadius: '8px',
            background: 'linear-gradient(180deg, #3D333C 0%, #242123 100%)',
          }}
        >
          {payList.map((item, index) => {
            const { data } = props;
            let activityRule = data?.activityRulesList[0] || {
              ruleTargetAmt: 0,
            };
            let moneyT = activityRule?.ruleTargetAmt;
            let wMoney = wallet?.money || 0;
            let isVT = wMoney >= moneyT;
            return (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  minHeight: '56px',
                  py: '10px',
                  px: '16px',
                  alignItems: item.type === 1 ? 'center' : 'flex-start',
                  justifyContent: 'space-between',
                  borderBottomWidth: '1px',
                  borderBottomStyle: 'solid',
                  borderBottomColor: '#323D4D',
                }}
                onClick={() => onSelect(item)}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: item.type === 1 ? 'center' : 'flex-start',
                  }}
                >
                  <img
                    src={item.icon}
                    alt=""
                    style={{
                      width: '28px',
                      height: '28px',
                      marginRight: '8px',
                      objectFit: 'cover',
                    }}
                  />
                  <Box>
                    <Typography
                      variant="subtitle2"
                      color={'text.primary'}
                      fontWeight={700}
                    >
                      {item.name}
                    </Typography>
                    <Box>
                      {item.type === 1 ? (
                        !isVT ? (
                          <Typography variant="body2" color={'text.secondary'}>
                            <span style={{ color: '#F14B73' }}>
                              {__('Insufficient balance')}
                            </span>
                            {` (${__('ymoney')}`}
                            <span
                              style={{ color: '#F14B73' }}
                            >{`R$${wallet?.money || 0}`}</span>
                            {')'}
                          </Typography>
                        ) : (
                          <Typography variant="body2" color={'text.secondary'}>
                            {`(${__('ymoney')}`}
                            <span
                              style={{ color: '#F1EE86' }}
                            >{`R$${wallet?.money || 0}`}</span>
                            {')'}
                          </Typography>
                        )
                      ) : null}
                      {item.type === 2 ? (
                        <Box
                          sx={{
                            bgcolor: '#3D333C',
                            borderRadius: '6px',
                            mr: '10px',
                            px: '8px',
                            py: '6px',
                          }}
                        >
                          <Typography
                            sx={{ fontSize: 10, color: 'text.secondary' }}
                          >
                            {__('minTip').replace('{min}', item?.min_money)}
                          </Typography>
                        </Box>
                      ) : null}
                    </Box>
                  </Box>
                </Box>
                <Checkbox
                  checked={item.isSel}
                  checkedIcon={
                    <CheckCircleIcon
                      sx={{ color: ColorfulBg(), fontSize: 20 }}
                    />
                  }
                  size="small"
                  icon={<RadioButtonUncheckedIcon />}
                  onClick={() => onSelect(item)}
                  sx={{ p: 0 }}
                ></Checkbox>
              </Box>
            );
          })}
          <LoadingButton
            fullWidth
            disabled={!isAvaliable}
            variant="contained"
            onClick={handleDeposit}
            loading={depositLoading}
            sx={{
              borderRadius: '8px',
            }}
            style={{
              backgroundColor: ColorfulBg(),
              opacity: isAvaliable ? 1 : 0.5,
            }}
          >
            {__('Deposit')}
          </LoadingButton>
        </Stack>
      </Box>
    </Box>
  );
};
const styles = {
  input: {
    height: '48px',
    bgcolor: 'card.main',
    '&.MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'divider', // 设置边框颜色
      },
      '&.Mui-disabled fieldset': {
        borderColor: 'divider', // 设置禁用状态下的边框颜色
      },
    },
  },
};
export default WeekCardFailDrawerConten;
