import ICON_CLOSE from '@/assets/icons/ic_close_snp.png';
import Center from '@/components/center';
import useAuth from '@/hooks/useAuth';
import useBoolean from '@/hooks/useBoolean';
import useLang from '@/hooks/useLang';
import { ColorfulBg } from '@/styles';
import { hideScrollbarY } from '@/utils/cssStyles';
import { LoadingButton } from '@mui/lab';
import { Box, Dialog, IconButton, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { langState } from '../../stores/lang';
import { LanguageType } from '../../types';

interface Props {
  open: boolean;
  onClose: () => void;
  pic?: string;
  title?: string;
  num?: number;
  resData?: any[];
}

const RedeemResDialog: React.FC<Props> = (props: Props) => {
  const { __ } = useLang();
  const [lang, setLang] = useRecoilState(langState);
  const { getWallet } = useAuth();
  const [loading, setLoading] = useBoolean();

  const handleSubmit = async () => {
    props.onClose();
  };
  useEffect(() => {
    getWallet();
  }, [props?.resData]);
  let data = props?.resData || [];
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      PaperProps={{ sx: { bgcolor: 'transparent', boxShadow: 'none' } }}
    >
      <Box>
        <Box
          bgcolor="background.paper"
          py={2}
          px={2}
          sx={{
            minWidth: '200px',
          }}
          borderRadius="16px"
        >
          <Box
            sx={{
              overflowX: 'scroll',
              width: '100%',
              maxWidth: '100%',
              ...hideScrollbarY,
            }}
            mt={'16px'}
          >
            <Stack
              direction="row"
              spacing="10px"
              sx={{
                justifyContent: data.length < 3 ? 'center' : 'flex-start',
              }}
            >
              {data.map((item, idx) => {
                return (
                  <Box>
                    <Center>
                      <img
                        src={item.pic}
                        alt=""
                        style={{
                          width: '120px',
                          minWidth: '120px',
                          height: '120px',
                          borderRadius: '16px',
                        }}
                      />
                    </Center>
                    <Typography
                      mt={1}
                      textAlign="center"
                      variant="body1"
                      fontWeight={500}
                    >
                      {`${
                        (lang === LanguageType.EN
                          ? item.prizeName.enUS
                          : item.prizeName.ptBR) ||
                        item.prizeName.zhCN ||
                        ''
                      }`}
                    </Typography>
                  </Box>
                );
              })}
            </Stack>
          </Box>
          <LoadingButton
            variant="contained"
            fullWidth
            sx={{
              boxShadow: 'none !important',
              mt: 2,
              borderRadius: '8px',
            }}
            style={{
              backgroundColor: ColorfulBg(),
            }}
            loading={loading}
            onClick={handleSubmit}
          >
            {__('Receive')}
          </LoadingButton>
        </Box>

        <Box sx={{ mt: 2, textAlign: 'center' }}>
          <IconButton size="small" onClick={props.onClose}>
            <img
              src={ICON_CLOSE}
              alt=""
              style={{
                width: 24,
                height: 24,
              }}
            />
          </IconButton>
        </Box>
      </Box>
    </Dialog>
  );
};

export default RedeemResDialog;
