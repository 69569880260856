import ICON_CLOSE from '@/assets/icons/ic_close_snp.png';
import model_aq from '@/assets/images/modal_aq.svg';
import model_head from '@/assets/images/model_head.svg';
import Center from '@/components/center';
import useAuth from '@/hooks/useAuth';
import useBoolean from '@/hooks/useBoolean';
import useLang from '@/hooks/useLang';
import { isOpenDepositState, showMoneyDialogState } from '@/stores/auth';
import { langState } from '@/stores/lang';
import { ColorfulBg } from '@/styles';
import { LanguageType } from '@/types';
import { hideScrollbarY } from '@/utils/cssStyles';
import { LoadingButton } from '@mui/lab';
import { Box, Dialog, IconButton, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

const MoneyDialog: React.FC = () => {
  const { __ } = useLang();
  const [lang, setLang] = useRecoilState(langState);
  const [showMoneyDialog, setShowMoneyDialog] =
    useRecoilState(showMoneyDialogState);
  const [openDeposit, setOpenDeposit] = useRecoilState(isOpenDepositState);

  const navigate = useNavigate();

  const { getWallet } = useAuth();
  const [loading, setLoading] = useBoolean();
  const handleClose = async () => {
    getWallet();
    setShowMoneyDialog({ open: false });
  };
  const handleSubmit = async () => {
    getWallet();
    setShowMoneyDialog({ open: false });

    if (showMoneyDialog?.showObject) {
      const { activityId, userActivityId } = showMoneyDialog?.showObject;
      setOpenDeposit(false);
      setTimeout(() => {
        navigate(`${showMoneyDialog.routePath}/${activityId}`, {
          state: { autoShow: true, useAId: userActivityId },
        });
      }, 400);
    }
  };

  return (
    <Dialog
      open={showMoneyDialog.open}
      onClose={handleClose}
      PaperProps={{ sx: { bgcolor: 'transparent', boxShadow: 'none' } }}
    >
      <Box pt={'88px'}>
        <Box bgcolor="background.paper" borderRadius="16px">
          <Box
            bgcolor="background.paper"
            py={2}
            px={2}
            sx={{
              minWidth: '250px',
              background:
                'linear-gradient(180deg, #E58CDB59 0%, #E58CDB00 100%)',
            }}
            borderRadius="16px"
          >
            <Center sx={{ width: '100%', mt: '-72px' }}>
              <img
                src={model_head}
                alt=""
                style={{
                  width: '104px',
                  minWidth: '104px',
                  height: '104px',
                }}
              />
            </Center>

            <Typography
              sx={{
                fontWeight: '700',
                fontStyle: 'italic',
                fontSize: 24,
                width: '100%',
                textAlign: 'center',
              }}
            >
              {__('congratulations')}
            </Typography>
            <Box
              sx={{
                overflowX: 'scroll',
                width: '100%',
                maxWidth: '100%',
                ...hideScrollbarY,
              }}
              mt={'16px'}
            >
              <Stack
                direction="row"
                spacing="10px"
                sx={{
                  justifyContent: 'center',
                }}
              >
                <Box>
                  <Center>
                    <img
                      src={showMoneyDialog?.pic}
                      alt=""
                      style={{
                        width: '120px',
                        minWidth: '120px',
                        height: '120px',
                        borderRadius: '16px',
                      }}
                    />
                  </Center>
                  <Typography
                    mt={1}
                    textAlign="center"
                    variant="body1"
                    sx={{
                      fontWeight: '700',
                      fontStyle: 'italic',
                      fontSize: 20,
                    }}
                  >
                    {`${
                      (lang === LanguageType.EN
                        ? showMoneyDialog?.contents?.enUS
                        : showMoneyDialog?.contents?.ptBR) ||
                      showMoneyDialog?.contents?.zhCN ||
                      ''
                    }`}
                  </Typography>
                </Box>
              </Stack>
            </Box>
            <Center sx={{ width: '100%' }}>
              <LoadingButton
                variant="contained"
                fullWidth
                sx={{
                  boxShadow: 'none !important',
                  mt: 2,
                  borderRadius: '999px',
                  width: '200px',
                }}
                style={{
                  backgroundColor: ColorfulBg(),
                }}
                loading={loading}
                onClick={handleSubmit}
              >
                {__('Receive')}
              </LoadingButton>
            </Center>
          </Box>
        </Box>

        <Box bgcolor="background.paper" borderRadius="16px" mt={'8px'}>
          <Box
            bgcolor="background.paper"
            py={'16px'}
            px={2}
            sx={{
              minWidth: '250px',
              background:
                'linear-gradient(180deg, #E58CDB59 0%, #E58CDB00 100%)',
            }}
            borderRadius="16px"
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                px: '48px',
                mt: '-30px',
              }}
            >
              <Box
                sx={{
                  width: '6px',
                  height: '20px',
                  borderRadius: '3px',
                  bgcolor: '#3D333C',
                }}
              ></Box>
              <Box
                sx={{
                  width: '6px',
                  height: '20px',
                  borderRadius: '3px',
                  bgcolor: '#3D333C',
                }}
              ></Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-around',
              }}
            >
              <Typography
                sx={{
                  fontWeight: '700',
                  fontStyle: 'italic',
                  fontSize: 20,
                }}
              >
                {__('Aguardando coleta diária')}
              </Typography>
              <img
                src={model_aq}
                alt=""
                style={{
                  width: '96px',
                  minWidth: '96px',
                  height: '96px',
                  marginLeft: -16,
                }}
              />
              <Typography
                sx={{
                  fontWeight: '700',
                  fontStyle: 'italic',
                  fontSize: 14,
                  ml: -1,
                  minWidth: '50px',
                }}
              >{`R$ ${showMoneyDialog?.showObject?.surpAmt || ''}`}</Typography>
            </Box>
            <Typography
              mt={-1}
              variant="body1"
              fontWeight={400}
              color={'text.secondary'}
            >
              {__('cardTip')}
            </Typography>
            <Typography
              my={1}
              variant="body1"
              fontWeight={500}
              color={'#E58CDB'}
              width={'100%'}
              textAlign={'center'}
              onClick={handleSubmit}
            >
              {__('View Records')}
              {' >'}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ mt: 2, textAlign: 'center' }}>
          <IconButton size="small" onClick={handleClose}>
            <img
              src={ICON_CLOSE}
              alt=""
              style={{
                width: 24,
                height: 24,
              }}
            />
          </IconButton>
        </Box>
      </Box>
    </Dialog>
  );
};

export default MoneyDialog;
