import AUDIO_CASH from '@/assets/audios/cash.mp3';
import { walletState } from '@/stores/auth';
import { playAudio } from '@/utils/speak';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

export const useMoneySound = () => {
  const wallet = useRecoilValue(walletState);
  const [isFisrt, setIsFisrt] = useState<boolean>(true);

  useEffect(() => {
    if (wallet?.money) {
      if (isFisrt) {
        setIsFisrt(false);
        return;
      }
      playAudio(AUDIO_CASH);
    }
  }, [wallet?.money, isFisrt]);
};
