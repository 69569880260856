import { FirstDepositDto } from '@/hooks/useFirstDepositBouns';
import useLang from '@/hooks/useLang';
import { ColorfulBg } from '@/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Box, Checkbox, Stack, Typography } from '@mui/material';

export const DepositActivitySelect = (props: {
  list: FirstDepositDto[];
  selected?: FirstDepositDto;
  onSelect: (item?: FirstDepositDto) => void;
}) => {
  const { __, getValueByLanguage } = useLang();

  return (
    <Box
      sx={{
        // bgcolor: 'background.default',
        p: 2,
      }}
    >
      {props.list.map((item) => {
        return (
          <Stack
            key={item.id}
            direction="row"
            alignItems="center"
            spacing={1}
            mb={1}
          >
            <Checkbox
              checked={props.selected?.id === item.id}
              checkedIcon={
                <CheckCircleIcon sx={{ color: ColorfulBg(), fontSize: 20 }} />
              }
              size="small"
              icon={<RadioButtonUncheckedIcon />}
              onClick={() => props.onSelect(item)}
              sx={{ p: 0 }}
            ></Checkbox>
            <Typography
              variant="body1"
              color="text.primary"
              fontWeight={500}
              onClick={() => props.onSelect(item)}
              sx={{ cursor: 'pointer' }}
            >
              {`${getValueByLanguage(item.title)}(${item.userTripTimes}/${
                item.activityTripTimes
              })`}
            </Typography>
          </Stack>
        );
      })}

      <Stack direction="row" alignItems="center" spacing={1}>
        <Checkbox
          checked={!props.selected}
          checkedIcon={<CheckCircleIcon sx={{ color: ColorfulBg() }} />}
          size="small"
          icon={<RadioButtonUncheckedIcon />}
          onClick={() => props.onSelect()}
          sx={{
            p: 0,
          }}
        ></Checkbox>
        <Typography
          variant="body2"
          color="text.primary"
          onClick={() => props.onSelect()}
          sx={{ cursor: 'pointer' }}
        >
          {__('No Reward Required')}
        </Typography>
      </Stack>
    </Box>
  );
};
