import useAuth from '@/hooks/useAuth';
import useLang from '@/hooks/useLang';
import { homeTab } from '@/stores/config';
import { HomeTab } from '@/types';
import { Box, Stack, Typography } from '@mui/material';
import { useRef } from 'react';
import { useRecoilValue } from 'recoil';
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { BackgroundColors, VipIcons, VipLevelSrcs } from '../consts';
import Bg from '../images/vip-box-bg.png';
import { VIPProcessBar } from './VipProcessBar';

import { useEffect } from 'react';

const BoxItem = (props: { vip: number; maxLevel: number; vipItem: any }) => {
  const { vip, user } = useAuth();

  const vipData = props.vipItem;
  const { __ } = useLang();

  if (!vipData || !user) {
    return null;
  }

  return (
    <Box pt={2}>
      <Box
        sx={{
          width: '332px',
          height: '186px',
          borderRadius: 1,
          bgcolor: BackgroundColors[props.vip],
          position: 'relative',
        }}
      >
        <img src={Bg} alt="" style={{ width: '100%', height: '100%' }} />
        <Box
          sx={{
            position: 'absolute',
            width: '80px',
            height: '80px',
            top: '-16px',
            right: '16px',
          }}
        >
          <img src={VipIcons[props.vip]} alt="" width={80} height={80} />
        </Box>
        <Box
          sx={{
            position: 'absolute',
            width: '300px',
            height: '186px',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            p: 2,
          }}
        >
          <Stack spacing={2} mt={1}>
            <Box>
              <img
                src={VipLevelSrcs[props.vip]}
                alt=""
                width={116}
                height={32}
              />
            </Box>
            <Box
              sx={{ display: props.vip == props.maxLevel ? 'none' : 'auto' }}
            >
              <Typography lineHeight={1} variant="body2">
                <span style={{ opacity: 0.56, marginRight: 4 }}>
                  {__('Recharge')}
                </span>
                R$
                {user?.protect_level_money || 0}
                <span
                  style={{ opacity: 0.56, marginRight: 4 }}
                >{` / R$${vipData.update_money}`}</span>
              </Typography>
              <VIPProcessBar
                vip={props.vip}
                total={+vipData.update_money}
                current={user.protect_level_money}
              />
            </Box>
            <Box>
              <Box
                sx={{ display: props.vip == props.maxLevel ? 'none' : 'auto' }}
              >
                <Typography lineHeight={1} variant="body2">
                  <span style={{ opacity: 0.56, marginRight: 4 }}>
                    {__('Bet Amount')}
                  </span>
                  R${user?.protect_level_dama || 0}
                  <span
                    style={{ opacity: 0.56, marginRight: 4 }}
                  >{` / R$${vipData.update_dama}`}</span>
                </Typography>
                <VIPProcessBar
                  vip={props.vip}
                  total={+vipData.update_dama}
                  current={user.protect_level_dama}
                />
              </Box>
              {props.vip < props.maxLevel ? (
                <Box mt={2}>
                  <Typography
                    lineHeight={1}
                    variant="body2"
                    color="text.secondary"
                  >
                    {__('completeTask').replace('{n}', `${props.vip + 1}`)}
                    <span style={{ fontSize: 14, color: '#ffffff' }}>{`V${
                      props.vip + 1
                    }`}</span>
                  </Typography>
                </Box>
              ) : props.vip == props.maxLevel ? (
                <Box mt={11}>
                  <Typography
                    lineHeight={1}
                    variant="body2"
                    color="text.secondary"
                  >
                    {__('maxVipTip')}
                  </Typography>
                </Box>
              ) : null}
            </Box>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export const VipBox = (props: { onChangeVip: (vip: number) => void }) => {
  const { vip, currentVip } = useAuth();
  let initVip = currentVip - 1 < 0 ? 0 : currentVip - 1;
  const vipSwiperRef = useRef<any>();
  let data = vip?.data || [];
  let vipData = data.filter((v) => v.level >= currentVip);
  const homeTabValue = useRecoilValue(homeTab);

  useEffect(() => {
    if (homeTabValue === HomeTab.Vip) {
      let index = vipData.findIndex((vData) => {
        return vData.level == currentVip;
      });
      vipSwiperRef?.current?.slideTo(index || 0);
    }
  }, [homeTabValue]);

  return (
    <Box sx={{ pl: 2 }}>
      <Swiper
        onSwiper={(s) => {
          vipSwiperRef.current = s;
        }}
        slidesPerView="auto"
        initialSlide={0}
        spaceBetween={12}
        onActiveIndexChange={(slide) => {
          props.onChangeVip(vipData[slide.activeIndex].level);
        }}
      >
        {vipData.map((item, index) => {
          return (
            <SwiperSlide key={item.id} style={{ width: 332 }}>
              <BoxItem vip={item.level} vipItem={item} maxLevel={data.length} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Box>
  );
};
