import ActivityPage from '@/pages/Activity';
import GameHistory from '@/pages/GameHistory';

import { IpLock } from '@/components/ip-lock';
import Layouts from '@/layouts';
import AccountBindPage from '@/pages/Account/Bind';
import BlindBox from '@/pages/BlindBox';
import WinningRecords from '@/pages/BlindBox/WinningRecords';
import GamePlayPage from '@/pages/GameInfo/GamePlay';
import GameListBonusBuy from '@/pages/GameList/BonusBuy';
import GameListGameShows from '@/pages/GameList/GameShows';
import GameListLiveCasino from '@/pages/GameList/LiveCasino';
import GameListSlotsPage from '@/pages/GameList/Slots';
import GameListTableGames from '@/pages/GameList/TableGames';
import { GiftPage } from '@/pages/Gift';
import Home from '@/pages/Home';
import LuckyDrawer from '@/pages/LuckyDrawer';
import PreferencesPage from '@/pages/Preferences';
import { RedeeCodePage } from '@/pages/RedeeCode';
import SettingPage from '@/pages/Setting';
import EditLoginPasswordPage from '@/pages/Setting/EditLoginPassword';
import EditPayPasswordPage from '@/pages/Setting/EditPayPassword';
import SetPayPasswordPage from '@/pages/Setting/SetPayPassword';
import { TestDeviceIdPage } from '@/pages/TestDeviceId';
import TransationPage from '@/pages/Transations';
import IndexPage from '@/pages/index';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

const MePage = React.lazy(() => import('@/pages/Me'));
const VipPage = React.lazy(() => import('@/pages/Vip'));
const RewardPage = React.lazy(() => import('@/pages/Reward'));
const SearchPage = React.lazy(() => import('@/pages/Search'));
const WalletPage = React.lazy(() => import('@/pages/Wallet'));
const DepositPage = React.lazy(() => import('@/pages/Wallet/Deposit'));
const MessagePage = React.lazy(() => import('@/pages/Message'));
const MessageDetailPage = React.lazy(() => import('@/pages/Message/detail'));
const NoticePage = React.lazy(() => import('@/pages/Notice'));
const NoticeDetailPage = React.lazy(() => import('@/pages/Notice/detail'));
const RecordsPage = React.lazy(() => import('@/pages/Records'));
const RecordsDetailPage = React.lazy(() => import('@/pages/Records/detail'));
const ReceiveRewards = React.lazy(() => import('@/pages/ReceiveRewards'));
const TaskPage = React.lazy(() => import('@/pages/Task'));
const FeedbackPage = React.lazy(() => import('@/pages/Feedback'));
const HelpsPage = React.lazy(() => import('@/pages/Helps'));
const HelpsDetailPage = React.lazy(() => import('@/pages/Helps/detail'));
const ContactPage = React.lazy(() => import('@/pages/Contact'));
const TermsPage = React.lazy(() => import('@/pages/Terms'));
const InvitePage = React.lazy(() => import('@/pages/Invite'));
const GameInfoPage = React.lazy(() => import('@/pages/GameInfo'));
const CategoryGameListPage = React.lazy(
  () => import('@/pages/CategoryGameList'),
);
const SigninPage = React.lazy(() => import('@/pages/Auth/Signin'));
const SignupPage = React.lazy(() => import('@/pages/Auth/Signup'));
const ResetPasswordPage = React.lazy(
  () => import('@/pages/Auth/ResetPassword'),
);
const CommissionsPage = React.lazy(() => import('@/pages/Commissions'));
const GameProviders = React.lazy(
  () => import('@/pages/WebCategory/GameProviders'),
);
const GameProviderPage = React.lazy(() => import('@/pages/GameProvider'));
const PopularGames = React.lazy(
  () => import('@/pages/WebCategory/PopularGames'),
);
const Slots = React.lazy(() => import('@/pages/WebCategory/Slots'));
const LiveCasino = React.lazy(() => import('@/pages/WebCategory/LiveCasino'));
const NewGames = React.lazy(() => import('@/pages/WebCategory/NewGames'));
const PromotionsPage = React.lazy(() => import('@/pages/Promotions'));
const PromotionMoneyPage = React.lazy(() => import('@/pages/Promotions/money'));
const InfomationPage = React.lazy(() => import('@/pages/Infomation'));
const InviteBoxPage = React.lazy(() => import('@/pages/InviteBoxPage'));
const CouponPage = React.lazy(() => import('@/pages/Coupon'));
const PackageRecordsPage = React.lazy(() => import('@/pages/PackageRecords'));
interface Props {}

const AppRouters: React.FC<Props> = (props: Props) => {
  return (
    <Routes>
      <Route element={<IpLock />}>
        <Route path="/signin" element={<SigninPage />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/reset-password" element={<ResetPasswordPage />} />

        <Route path="/set-payment" element={<SetPayPasswordPage />} />
        <Route path="/edit-payment" element={<EditPayPasswordPage />} />
        <Route path="/edit-login" element={<EditLoginPasswordPage />} />
        <Route path="/deposit" element={<DepositPage />} />
        <Route path="/gift" element={<GiftPage />} />
        <Route path="/winCode" element={<RedeeCodePage />} />

        <Route path="/play" element={<GamePlayPage />} />
        <Route path="/" element={<Layouts />}>
          <Route path="/receive-rewards" element={<ReceiveRewards />} />
          <Route path="/record-detail" element={<RecordsDetailPage />} />
          {/* 盲盒抽奖 */}
          <Route path="/lucky" element={<BlindBox />} />
          <Route path="/winnings" element={<WinningRecords />} />
          {/* 新台子页面 */}
          <Route path="/me" element={<MePage />} />
          <Route path="/records" element={<RecordsPage />} />
          <Route path="/setting" element={<SettingPage />} />
          {/* web 分类页面 */}
          <Route path="/popular-games" element={<PopularGames />} />
          <Route path="/live-casino" element={<LiveCasino />} />
          <Route path="/slots" element={<Slots />} />
          <Route path="/new-games" element={<NewGames />} />
          <Route path="/game-providers" element={<GameProviders />} />

          <Route path="/category" element={<CategoryGameListPage />} />
          <Route path="/provider" element={<GameProviderPage />} />

          <Route path="/account" element={<AccountBindPage />} />
          <Route path="/search" element={<SearchPage />} />
          <Route path="/wallet" element={<WalletPage />} />
          <Route path="/message/:id" element={<MessageDetailPage />} />
          <Route path="/message" element={<MessagePage />} />
          <Route path="/notice/:id" element={<NoticeDetailPage />} />
          <Route path="/notice" element={<NoticePage />} />
          <Route path="/task" element={<TaskPage />} />
          <Route path="/feedback" element={<FeedbackPage />} />
          <Route path="/helps/:id" element={<HelpsDetailPage />} />
          <Route path="/helps" element={<HelpsPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/terms" element={<TermsPage />} />
          <Route path="/invite" element={<InvitePage />} />
          <Route path="/game/info" element={<GameInfoPage />} />
          <Route path="/commissions" element={<CommissionsPage />} />
          <Route path="/vip" element={<VipPage />} />
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<IndexPage />} />
          <Route path="/promotion/money" element={<PromotionMoneyPage />} />
          <Route path="/promotions" element={<PromotionsPage />} />
          <Route path="/infomation/:id" element={<InfomationPage />} />
          <Route path="/invite-box" element={<InviteBoxPage />} />
          <Route path="/coupon" element={<CouponPage />} />
          <Route path="/packageRecords/:id" element={<PackageRecordsPage />} />
          <Route path="/testDeviceId" element={<TestDeviceIdPage />} />
          {/* 旧台子，待确认不需要后删除 */}
          <Route path="/game/list/slots" element={<GameListSlotsPage />} />
          <Route
            path="/game/list/live-casino"
            element={<GameListLiveCasino />}
          />
          <Route path="/game/list/game-shows" element={<GameListGameShows />} />
          <Route
            path="/game/list/table-games"
            element={<GameListTableGames />}
          />
          <Route path="/game/list/bonus-buy" element={<GameListBonusBuy />} />
          <Route path="/reward" element={<RewardPage />} />
          <Route path="/activity" element={<ActivityPage />} />
          <Route path="/transactions" element={<TransationPage />} />
          <Route path="/bets" element={<GameHistory />} />
          <Route path="/preferences" element={<PreferencesPage />} />
          <Route path="/spinners" element={<LuckyDrawer />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default AppRouters;
