import ICON_COUPON from '@/assets/icons/icon_coupon.svg';
import icon_history_active from '@/assets/icons/icon_history_active.png';
import ICON_HOME_ACTIVE from '@/assets/icons/icon_home_active.png';
import icon_message_active from '@/assets/icons/icon_message_active.png';
import ME_HOME from '@/assets/icons/me_home.svg';
import ICON_LIVE from '@/assets/icons/me_live.svg';
import ME_SETTINGS from '@/assets/icons/me_settings.svg';
import RedeemDialog from '@/components/coupon/RedeemDialog';
import RedeemFailDrawer from '@/components/coupon/RedeemFailDrawer';
import RedeemResDialog from '@/components/coupon/RedeemResDialog';
import useBoolean from '@/hooks/useBoolean';
import useLang from '@/hooks/useLang';
import { isOpenRedeeDialogState, supportUrlState } from '@/stores/auth';
import { tenantConfigState } from '@/stores/config';
import { Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import useResponsive from '@/hooks/useResponsive';
import { ListItemButton } from '@mui/material';

interface Props {
  active: string;
}

const MenusWeb: React.FC<Props> = (props: Props) => {
  const { __ } = useLang();
  const navigate = useNavigate();
  const supportUrl = useRecoilValue(supportUrlState);
  const tenantConfig = useRecoilValue(tenantConfigState);
  const [open, setOpen] = useBoolean();
  const [resOpen, setResOpen] = useBoolean();
  const [failOpen, setFailOpen] = useBoolean();
  const [redeeData, setRedeeData] = useState<any[]>([]);
  const [redeeFailData, setRedeeFailData] = useState<any[]>([]);
  const [isOpenRedeeDialog, setIsOpenRedeeDialog] = useRecoilState(
    isOpenRedeeDialogState,
  );

  const isMobile = useResponsive('down', 'md');

  const ItemBg = {
    borderRadius: '8px',
    overflow: 'hidden',
    width: '168px',
    p: 1.5,
  };

  const menuAry = [
    {
      key: 'home',
      img: ME_HOME,
      activeImg: ICON_HOME_ACTIVE,
      title: __('Home'),
      goPage: '/me',
    },
    // {
    //   key: 'reward',
    //   img: ICON_REWARD,
    //   activeImg: icon_reward_active,
    //   title: 'reward',
    //   goPage: '/reward',
    // },
    {
      key: 'history',
      img:
        tenantConfig?.iconConfig?.personalIcon?.history || icon_history_active,
      activeImg:
        tenantConfig?.iconConfig?.personalIcon?.history || icon_history_active,
      title: __('History'),
      goPage: '/records',
    },
    {
      key: 'message',
      img:
        tenantConfig?.iconConfig?.personalIcon?.message || icon_message_active,
      activeImg:
        tenantConfig?.iconConfig?.personalIcon?.message || icon_message_active,
      title: 'Message',
      goPage: '/message',
    },
    {
      key: 'coupon',
      img: tenantConfig?.iconConfig?.personalIcon?.coupon || ICON_COUPON,
      activeImg: tenantConfig?.iconConfig?.personalIcon?.coupon || ICON_COUPON,
      title: __('Coupon'),
      goPage: '/coupon',
    },
    // {
    //   key: 'exchange',
    //   img: ICON_EXCHANGE,
    //   title: 'Exchange',
    //   goPage: '/reward',
    // },
    // {
    //   key: 'account',
    //   img: ME_USER,
    //   activeImg: icon_account_active,
    //   title: __('Account'),
    //   goPage: '/account',
    // },
    {
      key: 'setting',
      img: tenantConfig?.iconConfig?.personalIcon?.setting || ME_SETTINGS,
      activeImg: tenantConfig?.iconConfig?.personalIcon?.setting || ME_SETTINGS,
      title: __('Setting'),
      goPage: '/setting',
    },
    {
      key: 'live',
      img: tenantConfig?.iconConfig?.personalIcon?.support || ICON_LIVE,
      title: __('Live Support'),
      goPage: '',
    },
  ];

  const menuClick = (menu: {
    key: string;
    img: string;
    title: any;
    goPage: string;
  }) => {
    if (menu.key === 'live') {
      if (supportUrl) {
        window.open(supportUrl, '_blank');
      }
      return;
    }
    if (menu.key === 'coupon') {
      setOpen.on();
      return;
    }
    navigate(menu.goPage);
  };
  const onRedeem = (data: any) => {
    setOpen.off();
    setIsOpenRedeeDialog(false);
    console.log('_data', data);
    if (Array.isArray(data) && data?.length > 0) {
      console.log('--data', data);
      setRedeeData(data);
      setResOpen.on();
    } else {
      console.log('++data', data);
      setRedeeFailData(data?.ruleDetails || []);
      setFailOpen.on();
    }
  };

  if (isMobile) return null;

  return (
    <Stack>
      <Stack
        sx={{
          bgcolor: 'background.paper',
          borderRadius: '10px',
          p: 2,
        }}
        spacing={1}
      >
        {menuAry.map((menu, index) => {
          let isActive = props.active === menu.key; //
          return (
            <ListItemButton
              key={index}
              sx={[
                ItemBg,
                {
                  bgcolor: isActive ? 'primary.main' : 'background.paper',
                },
              ]}
              onClick={() => menuClick(menu)}
            >
              <Stack alignItems="center" direction="row" spacing={2}>
                <img
                  style={{ width: 24, height: 24 }}
                  alt=""
                  src={isActive ? menu.activeImg : menu.img}
                />
                <Typography variant="body1" fontWeight={700}>
                  {menu.title}
                </Typography>
              </Stack>
            </ListItemButton>
          );
        })}
      </Stack>
      <RedeemDialog
        open={open || isOpenRedeeDialog}
        onClose={() => {
          setOpen.off();
          setIsOpenRedeeDialog(false);
        }}
        onRedee={onRedeem}
      />
      <RedeemResDialog
        resData={redeeData}
        open={resOpen}
        onClose={setResOpen.off}
      />
      <RedeemFailDrawer
        open={failOpen}
        onClose={setFailOpen.off}
        list={redeeFailData || []}
      />
    </Stack>
  );
};

export default MenusWeb;
