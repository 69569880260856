import { useAuthJump } from '@/hooks/useAuthJump';
import { adsState } from '@/stores/config';
import { Box } from '@mui/material';
import { useRecoilValue } from 'recoil';

export const HomeAds = () => {
  const adsValue = useRecoilValue(adsState);
  const { authJump } = useAuthJump();

  if (adsValue.length === 0) {
    return null;
  }

  const ad = adsValue[0];

  if (!ad) {
    return null;
  }

  return (
    <Box
      mb={2.5}
      borderRadius={'6px'}
      overflow={'hidden'}
      sx={{ cursor: 'pointer' }}
      onClick={() => {
        authJump(ad);
      }}
    >
      <img
        src={ad.pic}
        width={343}
        height={96}
        style={{ width: '100%' }}
        alt=""
      />
    </Box>
  );
};
