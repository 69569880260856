import useHeartbeat from '@/hooks/useHeartbeat';
import { useMoneySound } from '@/hooks/useMoneySound';
import { Box } from '@mui/material';

interface Props {}

const Heartbeat: React.FC<Props> = (props: Props) => {
  useHeartbeat(15 * 1000);
  useMoneySound();
  return <Box></Box>;
};

export default Heartbeat;
