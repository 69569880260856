import { useActivityScrollrMessages } from '@/hooks/useActivity';
import { ScrollMessageList } from '@/pages/Commissions/components/ScrollMessageList';
import { ActivityType } from '@/types';
import { Box } from '@mui/material';
import HomeSectionContainer from './SectionContainer';

export const HomeWinning = () => {
  // const [list, setList] = useState<ScrollMessageDto[]>(MOCK);
  const { messages, loading } = useActivityScrollrMessages(
    ActivityType.单次充值活动,
    true,
  );

  const list1 = messages.slice(0, 20);
  const list2 = messages.slice(20, 40);
  const list3 = messages.slice(40, 60);

  if (loading || messages.length === 0) {
    return null;
  }

  return (
    <Box mb={1.5}>
      <HomeSectionContainer title="Winning" hideViewAll>
        <Box>
          {list1.length > 0 && (
            <Box height="44px">
              <ScrollMessageList speed={50} list={list1} />
            </Box>
          )}
          {list2.length > 0 && (
            <Box height="44px">
              <ScrollMessageList speed={90} list={list2} delay={2} />
            </Box>
          )}
          {list3.length > 0 && (
            <Box height="44px">
              <ScrollMessageList speed={70} list={list3} delay={1} />
            </Box>
          )}
        </Box>
      </HomeSectionContainer>
    </Box>
  );
};
