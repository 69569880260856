import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface Props {
  onOpen: () => void;
}

export const InviteBoxPageMobileHeader = (props: Props) => {
  const navigate = useNavigate();

  return (
    <Stack
      pl={1.5}
      width="100%"
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ height: '48px' }}
      display={{ xs: 'flex', md: 'none' }}
    >
      <Box
        width="24px"
        height="24px"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          ml: '4px',
        }}
      >
        <IconButton
          color="info"
          sx={{
            background: 'rgba(255, 255, 255, 0.2)',
            width: '24px',
            height: '24px',
          }}
          onClick={() => {
            navigate('/');
          }}
        >
          <ArrowBackIosNewRoundedIcon sx={{ fontSize: 16 }} />
        </IconButton>
      </Box>

      <Box
        sx={{
          height: '32px',
          display: 'flex',
          alignItems: 'center',
          background: 'rgba(255, 255, 255, 0.2)',
          pl: 1.5,
          pr: 0.5,
          borderTopLeftRadius: 16,
          borderBottomLeftRadius: 16,
          cursor: 'pointer',
        }}
        onClick={props.onOpen}
      >
        <Typography variant="body1" mr={0.5}>
          Winning Record
        </Typography>
        <ArrowForwardIosRoundedIcon sx={{ fontSize: '14px' }} />
      </Box>
    </Stack>
  );
};

export const ViewRecordWebButton = (props: Props) => {
  return (
    <Box
      sx={{
        display: {
          xs: 'none',
          md: 'flex',
        },
        justifyContent: 'center',
        cursor: 'pointer',
        mt: 2,
      }}
    >
      <Box
        sx={{
          display: 'inline-flex',
          mx: 'auto',
          alignItems: 'center',
          background: 'rgba(255, 255, 255, 0.2)',
          px: 2,
          py: 1,
          borderRadius: 2,
          cursor: 'pointer',
        }}
        onClick={props.onOpen}
      >
        <Typography variant="body1" mr={0.5}>
          Winning Record
        </Typography>
        <ArrowForwardIosRoundedIcon sx={{ fontSize: '14px' }} />
      </Box>
    </Box>
  );
};
