import ic_lock from '@/assets/icons/icon_lock.png';
import IC_QUESTION from '@/assets/icons/icon_question.png';
import ic_un_lock from '@/assets/icons/icon_un_lock.png';
import CodeInput from '@/components/Auth/login/CodeInput';
import useAuth from '@/hooks/useAuth';
import useLang from '@/hooks/useLang';
import services from '@/services';
import {
  isOpenVipRebateState,
  isOpenWithdrawerState,
  userInfoState,
  vipListState,
  walletState,
  withdrawerTypeState,
  withdrawInfoState,
} from '@/stores/auth';
import { minTiXianState } from '@/stores/config';
import { ColorfulBg } from '@/styles';
import { customColor } from '@/theme/customColor';
import { LoadingButton } from '@mui/lab';
import FormControl from '@mui/material/FormControl';

import {
  Box,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { SnackbarUtils } from '../snackbar/SnackbarProvider';

interface Props {}

/**
 * 提现弹窗内容
 *
 * @param {Props} props
 * @return {*}
 */
const WithdrawDrawerConten = (props: Props) => {
  const { __ } = useLang();
  const [openWithdraw, setOpenWithdraw] = useRecoilState(isOpenWithdrawerState);
  const [withdrawerType, setWithdrawType] = useRecoilState(withdrawerTypeState);

  const info = useRecoilValue(withdrawInfoState);
  const wallet = useRecoilValue(walletState);
  const user = useRecoilValue(userInfoState);

  const minTiXianValue = useRecoilValue(minTiXianState);
  const vipList = useRecoilValue(vipListState);
  const setOpenVipRebate = useSetRecoilState(isOpenVipRebateState);
  const [code, setCode] = useState<string>('');

  const [amount, setAmount] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [cardNo, setCardNo] = useState<string>(user?.cpfCode || '');
  const [cardState, setCardState] = useState<boolean>(
    user?.cpfState != null && user?.cpfState != 0,
  );
  const [phoneBand, setPhoneBand] = useState<boolean>(
    user?.phone_bind != null && user?.phone_bind === 1,
  );
  const [phone, setPhone] = useState<string>(user?.phone || '');
  const [currenVip, setCurrenVip] = useState<any>(null);
  const [isShowCaptcha, setIsShowCaptcha] = useState<boolean>(
    user?.cpfState != 2,
  );
  const [onSel, setOnSel] = useState<boolean>(false);
  const [canSave, setCanSave] = useState<boolean>(false);
  const [withType, setWithType] = useState<string>(
    user?.cpfType === 1
      ? 'CPF'
      : user?.cpfType === 2
      ? 'Phone'
      : user?.cpfType === 3
      ? 'Email'
      : '',
  );
  const { getWallet, getUserInfo } = useAuth();
  const theme = useTheme();

  const totalAmount =
    info.outType === 2
      ? +(wallet?.agent_money || 0)
      : +(wallet?.cash_able_amt || 0);

  useEffect(() => {
    const getCurrenVip = async () => {
      if (Array.isArray(vipList)) {
        const current = vipList.find((i) => +i.is_user_vip === 1);
        setCurrenVip(current || null);
      }
    };
    if (openWithdraw) {
      getWallet();
      getCurrenVip();
      _onInput('', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openWithdraw]);

  const isValidEmail = (email: string) => {
    let emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const _vipRebateRule = () => {
    setOpenVipRebate(true);
  };

  const handleSendCode = async () => {
    if (!phone) {
      return false;
    }
    return await services.auth.sendSMS({
      phone,
      type: 6,
    });
  };

  const _onInput = (type: string, txt: string) => {
    let params = {
      cardNo,
      phone,
      code,
      amount,
    };
    switch (type) {
      case 'card':
        setCardNo(txt);
        params.cardNo = txt;
        break;
      case 'phone':
        setPhone(txt);
        params.phone = txt;
        break;
      case 'code':
        setCode(txt);
        params.code = txt;
        break;
      case 'amount':
        setAmount(txt);
        params.amount = txt;
        break;
    }

    if (
      user?.cpfState != 2 &&
      params.cardNo &&
      params.phone &&
      params.code &&
      params.amount
    ) {
      setCanSave(true);
    } else if (
      user?.cpfState == 2 &&
      params.cardNo &&
      params.phone &&
      params.amount
    ) {
      setCanSave(true);
    } else {
      setCanSave(false);
    }
  };

  const handleAmount = (money: string) => {
    const moneyT = money.replace(/[^\d.]/g, '');
    if (money.length > amount.length) {
      var regex = /^\d+(\.\d{1,2})?$/;
      if (regex.test(moneyT)) {
        setAmount(moneyT);
        _onInput('amount', moneyT);
      }
    } else {
      setAmount(moneyT);
      _onInput('amount', moneyT);
    }
  };

  const handleSubmit = async () => {
    if (loading) {
      return;
    }

    // 未设置支付密码
    // if (user?.pay_password !== 1) {
    //   setOpenConfitSetPayPwd(true);
    //   return;
    // }

    if (withType === 'Email' && !isValidEmail(cardNo)) {
      SnackbarUtils.error(__('Incorrect email format'));
      return;
    }

    setLoading(true);
    let requestType = 3;
    if (!user?.cpfId) {
      requestType = 1;
    } else if (cardNo != user?.cpfCode) {
      requestType = 2;
    }

    let params = {
      requestType,
      phone,
      code,
      type: withType === 'CPF' ? 1 : withType === 'Phone' ? 2 : 3,
      cpfCode: cardNo,
      cpfId: user?.cpfId,
      walletType: withdrawerType,
      amt: parseFloat(amount),
    };

    if (!user?.cpfId) {
      delete params.cpfId;
    }

    const isSuccess = await services.wallet.withdraw(params);

    if (isSuccess) {
      getWallet();
      getUserInfo();
      setOpenWithdraw(false);
      setAmount('');
      SnackbarUtils.success(__('Successfully'));
    }

    setLoading(false);
  };

  return (
    <Box sx={{ width: '100%', pb: 2 }} pb={'8px'}>
      <Box px={2.5} pt={2.5} pb={4}>
        <Stack spacing={1.5}>
          <FormControl sx={{ minWidth: 80, p: 0 }}>
            {withType === '' && !onSel ? (
              <InputLabel variant={'filled'}>
                {__('please choose withdraws type')}
              </InputLabel>
            ) : null}

            <Select
              value={withType}
              size="small"
              sx={styles.input}
              disabled={cardState}
              onChange={(e) => setWithType(e.target.value)}
              onOpen={() => {
                setOnSel(true);
              }}
              onClose={() => {
                setOnSel(false);
              }}
            >
              <MenuItem value={'CPF'}>{'CPF'}</MenuItem>
              <MenuItem value={'Phone'}>Phone</MenuItem>
              <MenuItem value={'Email'}>Email</MenuItem>
            </Select>
          </FormControl>
          <OutlinedInput
            value={cardNo}
            disabled={cardState}
            sx={styles.input}
            onChange={(e) => setCardNo(e.target.value)}
            placeholder={
              withType === 'CPF'
                ? __('Enter CPF')
                : withType === 'Phone'
                ? __('Enter CPF Phone')
                : __('Enter Email')
            }
            startAdornment={
              withType === 'Phone' ? (
                <InputAdornment position="start">
                  <MenuItem>+55</MenuItem>
                </InputAdornment>
              ) : null
            }
            endAdornment={
              <InputAdornment
                position="end"
                sx={{ width: '16px', height: '16px', minWidth: '16px' }}
              >
                <img
                  src={cardState ? ic_lock : ic_un_lock}
                  alt="coin"
                  style={{ width: 16, height: 16 }}
                />
              </InputAdornment>
            }
          />
          <OutlinedInput
            sx={styles.input}
            disabled={phoneBand}
            value={phone}
            type="number"
            onChange={(e) => _onInput('phone', e.target.value)}
            placeholder={__('Please enter phone number')}
            startAdornment={
              <InputAdornment position="start">
                <MenuItem>+55</MenuItem>
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment
                position="end"
                sx={{ width: '16px', height: '16px', minWidth: '16px' }}
              >
                <img
                  src={phoneBand ? ic_lock : ic_un_lock}
                  alt="coin"
                  style={{ width: 16, height: 16 }}
                />
              </InputAdornment>
            }
          ></OutlinedInput>
          {isShowCaptcha && (
            <Stack spacing={{ xs: 1.5, md: 2 }}>
              <CodeInput
                disabled={!cardNo || !phone}
                code={code}
                sx={styles.input}
                onChange={(v) => _onInput('code', v)}
                onSendSMS={handleSendCode}
                placeholder={__('Please enter verify code')}
              />
            </Stack>
          )}

          <Stack>
            <OutlinedInput
              value={amount}
              onChange={(e) => handleAmount(e.target.value)}
              sx={styles.input}
              type="number"
              inputProps={{
                inputMode: 'decimal',
                pattern: '[0-9]*[.]?[0-9]*',
              }}
              startAdornment={<Typography variant="body1">R$</Typography>}
              placeholder={__('withTip')}
            ></OutlinedInput>
            <Stack
              sx={{
                width: '100%',
                marginTop: '8px',
              }}
              direction="row"
              justifyContent="flex-end"
            >
              <Typography variant="body1" color="text.disabled">
                {`${__('Withdrawable Amount')}: `}
                <Link variant="body1" color="common.white">
                  {`R$ ${totalAmount.toFixed(2)}`}
                </Link>
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        {currenVip && withdrawerType == 1 ? (
          <Box
            sx={{
              bgcolor: alpha(theme.palette.primary.main, 0.08),
              borderRadius: '10px',
              border: '1px solid',
              borderColor: 'primary.main',
              p: '8px',
              width: '100%',
              marginTop: '16px',
              display: 'none',
            }}
          >
            <Stack
              direction={'row'}
              sx={{
                width: '100%',
                marginTop: '4px',
              }}
              justifyContent="space-between"
            >
              <Typography variant="body1" color="text.disabled">
                {'Level'}
              </Typography>
              <Stack direction={'row'} alignItems="center">
                <img
                  src={IC_QUESTION}
                  alt="coin"
                  style={{ width: 13, height: 14, marginRight: '4px' }}
                />
                <Typography variant="body1" color="common.white" ml={'4px'}>
                  {currenVip?.title || ''}
                </Typography>
              </Stack>
            </Stack>
            <Stack
              direction={'row'}
              sx={{
                width: '100%',
                marginTop: '8px',
              }}
              justifyContent="space-between"
            >
              <Typography variant="body1" color="text.disabled">
                {__('Withdrawal Fee')}
              </Typography>
              <Typography variant="body1" color="text.white">
                {`${currenVip?.out_money_commission || ''}%`}
              </Typography>
            </Stack>
          </Box>
        ) : null}
        {withdrawerType == 1 ? (
          <Typography
            variant="body1"
            color="text.disabled"
            mt={'16px'}
            sx={{ display: 'none' }}
          >
            {`${__('The minimum withdrawal value is')} `}
            <Link variant="body1" color="common.white">
              {`R$ ${minTiXianValue.toFixed(2)}`}
            </Link>
          </Typography>
        ) : null}
      </Box>
      <LoadingButton
        disabled={!canSave}
        variant="contained"
        fullWidth
        sx={{
          boxShadow: 'none !important',
          borderRadius: '8px',
          mx: '16px',
          width: 'calc(100% - 32px)',
        }}
        style={{
          backgroundColor: canSave
            ? ColorfulBg()
            : customColor.disable_button_bg,
        }}
        loading={loading}
        onClick={handleSubmit}
      >
        {__('Submit')}
      </LoadingButton>
    </Box>
  );
};
const styles = {
  input: {
    height: '48px',
    bgcolor: 'card.main',
    '&.MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'divider', // 设置边框颜色
      },
      '&.Mui-disabled fieldset': {
        borderColor: 'divider', // 设置禁用状态下的边框颜色
      },
    },
  },
};
export default WithdrawDrawerConten;
