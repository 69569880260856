import IMAGE_COIN from '@/assets/icons/coin.png';
import ICON_REFRESH from '@/assets/icons/ic_refresh.svg';
import useAuth from '@/hooks/useAuth';
import {
  Box,
  CircularProgress,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { useState } from 'react';

export const MyWallet = () => {
  const { wallet, getWallet } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const handleRefreshWallet = async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    await getWallet();
    setIsLoading(false);
  };

  if (isLoading && !wallet) {
    return (
      <Box mt={1}>
        <Skeleton variant="rectangular" width={'70%'} height={45} />
      </Box>
    );
  }

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <img src={IMAGE_COIN} alt="" style={{ width: 24, height: 24 }} />
      <Typography
        mr={0.5}
        variant="h1"
        fontSize={{ md: '40px' }}
        fontWeight={700}
      >
        {(+(wallet?.money || 0)).toFixed(2)}
      </Typography>
      {isLoading ? (
        <CircularProgress size={24} color="info" />
      ) : (
        <img
          src={ICON_REFRESH}
          alt="123"
          style={{ width: 24, height: 24, cursor: 'pointer' }}
          onClick={handleRefreshWallet}
        />
      )}
    </Stack>
  );
};
