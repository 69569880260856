import useLang from '@/hooks/useLang';
import useResponsive from '@/hooks/useResponsive';
import { weekCardDto } from '@/hooks/useWeekCardBonus';
import { globalMaxWidth } from '@/theme';
import { Box, Dialog, Drawer } from '@mui/material';
import DrawerHeder from '../../pages/Me/components/DrawerHeder';
import WeekCardDepositDrawerConten from './WeekCardDepositDrawerConten';

interface Props {
  open: boolean;
  data: weekCardDto | null;
  onClose: () => void;
  onPayCb: (url: string) => void;
}

const WeekCardDepositDrawer = (props: Props) => {
  const { __ } = useLang();
  const isMobile = useResponsive('down', 'md');

  if (isMobile) {
    return (
      <Drawer
        anchor={isMobile ? 'bottom' : 'right'}
        open={props.open}
        // onOpen={() => setOpenWithdraw(true)}
        ModalProps={{
          container: document.getElementById('root') as HTMLElement,
        }}
        onClose={props.onClose}
        PaperProps={{
          sx: {
            borderRadius: '24px 24px 0 0',
            width: '100%',
            maxWidth: globalMaxWidth,
            left: {
              xs: 0,
              sm: `calc(50% - ${globalMaxWidth / 2}px)`,
            },
          },
        }}
      >
        <Box
          bgcolor="transparent"
          position="relative"
          style={{
            width: Math.min(window.innerWidth, globalMaxWidth),
            // height: isMobile ? '75vh' : '100vh',
            overflowY: 'hidden',
          }}
          sx={{ bgcolor: 'card.main' }}
        >
          <DrawerHeder
            title={__('Payment confirmation')}
            onClose={props.onClose}
          />

          <WeekCardDepositDrawerConten
            data={props.data}
            onPayCb={props.onPayCb}
          />
        </Box>
      </Drawer>
    );
  }
  return (
    <Dialog
      open={props.open}
      PaperProps={{
        sx: {
          width: '400px',
          maxWidth: '400px',
          borderRadius: '20px 20px 0 0',
        },
      }}
      onClose={props.onClose}
      aria-labelledby="sign-in"
    >
      <Box
        bgcolor="transparent"
        position="relative"
        style={{
          width: '400px',
          overflowY: 'hidden',
        }}
        sx={{ bgcolor: 'card.main' }}
      >
        ,
        <DrawerHeder
          title={__('Payment confirmation')}
          onClose={props.onClose}
        />
        <WeekCardDepositDrawerConten
          data={props.data}
          onPayCb={props.onPayCb}
        />
      </Box>
    </Dialog>
  );
};

export default WeekCardDepositDrawer;
