import ICON_CLOSE from '@/assets/icons/ic_close_snp.png';
import Giftcode from '@/assets/images/Giftcode.png';
import Giftcode_pt from '@/assets/images/Giftcode_pt.png';
import redeeTopTxt from '@/assets/images/redeeTopTxt.png';
import redeeTopTxt_pt from '@/assets/images/redeeTopTxt_pt.png';
import reedeBg from '@/assets/images/reedeBg.png';
import { SnackbarUtils } from '@/components/snackbar/SnackbarProvider';
import useLang from '@/hooks/useLang';
import services from '@/services';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  OutlinedInput,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { LanguageType } from '../../types';

interface Props {
  open: boolean;
  onClose: () => void;
  onRedee: (data: any) => void;
  pic?: string;
  title?: string;
  num?: number;
}

const RedeemDialog: React.FC<Props> = (props: Props) => {
  const [code, setCode] = useState<string>('');
  const { __ } = useLang();
  const { language } = useLang();
  const [loading, setLoading] = useState<boolean>(false);
  const onClose = () => {
    props.onClose();
    setCode('');
  };

  const onRedee = async () => {
    if (!code) {
      return;
    }

    setLoading(true);
    let res = await services.config.redeemCode(code);
    setLoading(false);

    if (!res) {
      return;
    }
    if (res?.data?.userActivityState === 3) {
      SnackbarUtils.warning(__('same tip'), void 0, 2500);
      return;
    }
    props.onRedee(res?.data || {});
    setCode('');
  };
  const onPaster = async () => {
    const text = await navigator?.clipboard?.readText();
    setCode(text);
  };
  return (
    <Dialog
      open={props.open}
      onClose={onClose}
      PaperProps={{
        sx: { bgcolor: 'transparent', boxShadow: 'none' },
      }}
      container={() => document.getElementById('root') as HTMLElement}
    >
      <Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            backgroundImage: `url(${reedeBg})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            width: '311px',
            height: '386px',
            borderRadius: '16px',
            alignItems: 'center',
          }}
        >
          <img
            src={language === LanguageType.EN ? redeeTopTxt : redeeTopTxt_pt}
            alt=""
            style={{
              width: '247px',
              height: language === LanguageType.EN ? '62px' : '58px',
              marginTop: '28px',
            }}
          />
          <img
            src={language === LanguageType.EN ? Giftcode : Giftcode_pt}
            alt=""
            style={{
              width: 'auto',
              height: '18px',
              marginTop: '43px',
            }}
          />
          <OutlinedInput
            value={code}
            onChange={(e) => setCode(e.target.value)}
            size="medium"
            sx={{
              height: '40px',
              width: '191px',
              bgcolor: '#F6C093',
              mt: '16px',
              color: '#1D2026',
            }}
            endAdornment={
              <Typography
                textAlign="center"
                variant="body1"
                fontWeight={500}
                sx={{ color: '#7E3A13' }}
                onClick={onPaster}
              >
                {__('paster')}
              </Typography>
            }
          />

          <Button
            sx={{
              width: '159px',
              height: '40px',
              marginTop: '59px',
              background: '#f9dec7 !important',

              borderRadius: 999,
            }}
            onClick={onRedee}
          >
            {loading ? (
              <CircularProgress size={16} sx={{ color: '#231F26' }} />
            ) : (
              <Box
                className="font-baloo"
                sx={{
                  background:
                    'linear-gradient(90deg, #231F26 0%, #8A4823 100%)',
                  backgroundClip: 'text',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  fontSize: 18,
                  fontWeight: 700,
                  marginTop: '3px',
                }}
              >
                {__('Redeem now')}
              </Box>
            )}
          </Button>

          {/* <img
            src={language === LanguageType.EN ? reedeBtn : reedeBtn_pt}
            alt=""
            style={{
              width: '159px',
              height: '40px',
              marginTop: '59px',
            }}
            onClick={onRedee}
          /> */}
        </Box>

        <Box sx={{ mt: 2, textAlign: 'center' }}>
          <IconButton size="small" onClick={onClose}>
            <img
              src={ICON_CLOSE}
              alt=""
              style={{
                width: 24,
                height: 24,
              }}
            />
          </IconButton>
        </Box>
      </Box>
    </Dialog>
  );
};

export default RedeemDialog;
