import { Box } from '@mui/material';
import Lottie from 'lottie-web';
import { useEffect, useRef } from 'react';
import JSON from './loading.json';

export const SuspenseLoading = () => {
  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CommonLoading />
    </Box>
  );
};

export const CommonLoading = (props: { size?: number }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      Lottie.loadAnimation({
        container: ref.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: JSON,
      });
    }
  }, []);
  return (
    <div
      ref={ref}
      style={{ width: props.size || 100, height: props.size || 100 }}
    ></div>
  );
};
