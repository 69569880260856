import rightIcon from '@/assets/icons/ic_right.svg';
import ErrorIcon from '@/assets/icons/snake_fail.png';
import SuccessIcon from '@/assets/icons/snake_success.png';
import { useAuthJump } from '@/hooks/useAuthJump';
import useLang from '@/hooks/useLang';
import { IBannerItem } from '@/types/config';
import { Box, Stack, Typography } from '@mui/material';

interface Props {
  list: any[];
  onClose: () => void;
}

/**
 * 提现弹窗内容
 *
 * @param {Props} props
 * @return {*}
 */
const RedeemFailDrawerConten = (props: Props) => {
  const { __ } = useLang();
  const { authJump } = useAuthJump();
  const goPage = (path: string, canGo: boolean) => {
    if (!canGo) {
      return;
    }
    props.onClose && props.onClose();

    const data: IBannerItem = {
      url: path,
      target: 0,
      group_id: 0,
      title: '',
      pic: '',
    };

    setTimeout(() => {
      authJump(data);
    }, 200);
  };
  return (
    <Box sx={{ width: '100%', pb: { xs: 2, md: 0 } }} pb={'8px'}>
      <Box px={2.5} pt={2.5} pb={4}>
        <Typography variant="body1" color={'text.primary'} mb={'16px'}>
          {__('Redeem when conditions are met')}
        </Typography>
        <Stack
          sx={{
            borderRadius: '8px',
            px: '16px',
            border: { xs: '1px solid #323D4D', md: '1px solid #323D4D' },
          }}
        >
          {props.list.map((item, index) => {
            let isSuccess =
              parseInt(item?.currentValue + '' || '0', 10) >=
              parseInt(item?.targetValue + '' || '0', 10);
            return (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  minHeight: '56px',
                  py: '10px',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  borderBottomWidth: '1px',
                  borderBottomStyle: 'solid',
                  borderBottomColor: '#FFFFFF26',
                }}
                onClick={() => {
                  goPage(item.routePath, !isSuccess && item?.routePath);
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <img
                    src={isSuccess ? SuccessIcon : ErrorIcon}
                    alt=""
                    width={20}
                    height={20}
                    style={{ marginRight: '8px' }}
                  />
                  <Box>
                    <Typography
                      variant="subtitle2"
                      color={'text.primary'}
                      fontWeight={500}
                    >
                      {item.ruleName}
                    </Typography>
                    <Typography variant="body2" color={'text.secondary'}>
                      <span style={{ color: '#fff', fontWeight: 'bold' }}>
                        {item.currentValue}
                      </span>
                      {`/${item.value}`}
                      <span> {item?.ruleUnit || ''}</span>
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  {isSuccess ? (
                    <Typography variant="body1" color={'#06C687'}>
                      {__('Completed')}
                    </Typography>
                  ) : item?.routePath ? (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        color={'text.primary'}
                        fontWeight={500}
                      >
                        {item?.routeName || ''}
                      </Typography>
                      <img src={rightIcon} alt="" width={16} height={16} />
                    </Box>
                  ) : null}
                </Box>
              </Box>
            );
          })}
        </Stack>
      </Box>
    </Box>
  );
};
const styles = {
  input: {
    height: '48px',
    bgcolor: 'card.main',
    '&.MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'divider', // 设置边框颜色
      },
      '&.Mui-disabled fieldset': {
        borderColor: 'divider', // 设置禁用状态下的边框颜色
      },
    },
  },
};
export default RedeemFailDrawerConten;
