import { atom } from 'recoil';
import { IUserInfo } from '../types';
import { IWallet } from '../types/wallet';

export const isLoginState = atom<boolean | null>({
  key: 'isLoginState',
  default: null,
});

export const userInfoState = atom<IUserInfo | null>({
  key: 'userInfoState',
  default: null,
});

export const walletState = atom<IWallet | null>({
  key: 'walletState',
  default: null,
});

export const isFetchingUserInfo = atom<boolean>({
  key: 'isFetchingUserInfo',
  default: false,
});

export const isOpenLoginModalState = atom<boolean>({
  key: 'isOpenLoginModalState',
  default: false,
});

export const isOpenRegisterModalState = atom<boolean>({
  key: 'isOpenRegisterModalState',
  default: false,
});
export const isOpenForgetPwModalState = atom<boolean>({
  key: 'isOpenForgetPwModalState',
  default: false,
});

export const isOpenLoginDrawerState = atom<boolean>({
  key: 'isOpenLoginDrawerState',
  default: false,
});
export const isOpenRegisterDrawerState = atom<boolean>({
  key: 'isOpenRegisterDrawerState',
  default: false,
});

export const isOpenPasswordDrawerState = atom<boolean>({
  key: 'isOpenPasswordDrawerState',
  default: false,
});

export const isOpenUserMenuDrawerState = atom<boolean>({
  key: 'isOpenUserMenuDrawerState',
  default: false,
});

export const isOpenWithdrawerState = atom<boolean>({
  key: 'isOpenWithdrawerState',
  default: false,
});

export const withdrawerTypeState = atom<number>({
  key: 'withdrawerTypeState',
  default: 0,
});

export const isOpenWithdrawerValidState = atom<boolean>({
  key: 'isOpenWithdrawerValidState',
  default: false,
});

// 去设置支付密码确认弹窗
export const isOpenConfirmSetPayPwdDrawState = atom<boolean>({
  key: 'isOpenConfirmSetPayPwdDrawState',
  default: false,
});

// 支付密码设置弹窗
export const isOpenSetPayPwdDrawerState = atom<boolean>({
  key: 'isOpenSetPayPwdDrawerState',
  default: false,
});

// 去绑定cpf
export const isOpenBindCPFDrawState = atom<boolean>({
  key: 'isOpenBindCPFDrawState',
  default: false,
});

// 去绑定手机
export const isOpenBindPhoneDrawerState = atom<boolean>({
  key: 'isOpenBindPhoneDrawerState',
  default: false,
});

// 提现cpf的id
export const withdrawInfoState = atom<{
  cpfId: string;
  outType: number;
  cpfInfo?: any;
}>({
  key: 'withdrawInfoState',
  default: {
    cpfId: '',
    outType: 1,
  },
});

export const supportUrlState = atom<string>({
  key: 'supportUrlState',
  default: '',
});

export const isOpenVipDialogState = atom<boolean>({
  key: 'isOpenVipDialogState',
  default: false,
});

export const isOpenDepositState = atom<boolean>({
  key: 'isOpenDepositState',
  default: false,
});

export const depositTabState = atom<'amount' | 'package'>({
  key: 'depositTabState',
  default: 'amount',
});

export const isOpenSignSuccessState = atom<boolean>({
  key: 'isOpenSignSuccessState',
  default: false,
});

export const isOpenVipRebateState = atom<boolean>({
  key: 'isOpenVipRebateState',
  default: false,
});

export const isOpenMessageState = atom<boolean>({
  key: 'isOpenMessageState',
  default: false,
});

export const openMessageIdState = atom<number>({
  key: 'openMessageIdState',
  default: -1,
});

export const vipListState = atom<Array<any>>({
  key: 'vipListState',
  default: [],
});

export const signListState = atom<Array<any>>({
  key: 'signListState',
  default: [],
});

export const todaySignListState = atom<Array<any>>({
  key: 'todaySignListState',
  default: [],
});

export const isOpenLRModalState = atom<boolean>({
  key: 'isOpenLRModalState',
  default: false,
});

export const LRModalInitalPage = atom<number>({
  key: 'LRModalInitalPage',
  default: -1,
});

export const meDrawerState = atom<boolean>({
  key: 'meDrawerState',
  default: false,
});

export const agentLevelState = atom<Array<any>>({
  key: 'agentLevelState',
  default: [],
});

export const showIOSDownLoadState = atom<boolean>({
  key: 'showIOSDownLoadState',
  default: false,
});

export const isOpenVerifyAccountState = atom<string>({
  key: 'isOpenVerifyAccountState',
  default: '',
});

export const isOpenTransferState = atom<boolean>({
  key: 'isOpenTransferState',
  default: false,
});

export const showBottomMenuState = atom<boolean>({
  key: 'showBottomMenuState',
  default: false,
});

export const isOpenBoxNumTipState = atom<boolean>({
  key: 'isOpenBoxNumTipState',
  default: false,
});

export const isOpenMemberTipState = atom<boolean>({
  key: 'isOpenMemberTipState',
  default: false,
});

export const isOpenRedeeDialogState = atom<boolean>({
  key: 'isOpenRedeeDialogState',
  default: false,
});

export const selActivityState = atom<any>({
  key: 'selActivityState',
  default: {},
});

export const failAccountState = atom<string>({
  key: 'failAccountState',
  default: '',
});
export const showMoneyDialogState = atom<any>({
  key: 'showMoneyDialogState',
  default: { open: false },
});
export const cacheIPTimeState = atom<any>({
  key: 'cacheIPTimeState',
  default: {},
});
