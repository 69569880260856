import MoneyDialog from '@/components/MoneyDialog/MoneyDialog';
import { HeartbeatMsg } from '@/components/heartbeat-msg';
import {
  useHeaderTransparentBackground,
  useHomeTabScrollToTop,
} from '@/hooks/useScrollToTop';
import HomeHeaderGuide from '@/layouts/Header/HomeHeaderGuide';
import IOSDownLoadDrawer from '@/layouts/HoverMenu/IOSDownLoadDrawer';
import { isLoginState } from '@/stores/auth';
import { homeTab, isShowHomeGuide } from '@/stores/config';
import { HomeTab, MessageShowLevel } from '@/types';
import { AppBar, Box, CssBaseline, Toolbar } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { Outlet, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import {
  footerbarHeight,
  globalMaxWidth,
  mobileHeaderbarHeight,
  webHeaderbarHeight,
} from '../theme';
import MoblieAppbar from './Footerbar';
import Header from './Header';
// import { MobileFooterButtons } from './MobileFooterButtons';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme }) => ({
    // backgroundColor: theme.background,
    flexGrow: 1,
    position: 'relative',

    margin: '0px',
    minHeight: '100vh',
    // minHeight: `calc(100vh + ${footerHeight + footerbarHeight}px)`,
    maxWidth: '100vw',
    width: '100%',
    overflowX: 'hidden',
    padding: `${mobileHeaderbarHeight}px 16px ${0}px`,

    // borderRadius: `${theme?.customization?.borderRadius}px`,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    // [theme.breakpoints.up('md')]: {
    //   marginLeft: 0,
    //   width: `calc(100% - ${drawerWidth}px)`,
    //   // width: '100%',
    // },
    // [theme.breakpoints.down('md')]: {
    //   margin: '0px',
    //   width: '100%',
    //   minHeight: '100vh',
    //   maxWidth: '100vw',
    //   // width: `calc(100% - ${drawerWidth}px)`,
    //   overflowX: 'hidden',
    //   padding: `${mobileHeaderbarHeight}px 16px ${footerbarHeight}px`,
    // },
    // [theme.breakpoints.down('sm')]: {
    //   margin: '0px',
    //   minHeight: '100vh',
    //   // minHeight: `calc(100vh + ${footerHeight + footerbarHeight}px)`,
    //   maxWidth: '100vw',
    //   width: '100%',
    //   overflowX: 'hidden',
    //   padding: `${mobileHeaderbarHeight}px 16px ${0}px`,
    // },
    paddingBottom: 0,
  }),
);

const NO_FOOTER_PATHS = [
  '/account',
  '/wallet',
  '/deposit',
  '/vip',
  '/commissions',
  '/records',
  '/setting',
];

const NO_HEADER_PATHS: string | string[] = [];

const APP_BAR_PATHS = ['/', '/commissions', '/vip', '/lucky', '/me'];
const MOBILE_FULL_SCREEN_PATHS = [
  '/lucky',
  '/invite-box',
  '/commissions',
  '/coupon',
  '/promotion/money',
];
export const DOWNLOAD_GUIDE_PATHS = ['/'];

const isFullScreen = (pathname: string, homeTab: HomeTab) => {
  const isSpinner = homeTab === HomeTab.Spinner && ['/'].includes(pathname);
  const isInvite = homeTab === HomeTab.Invite && ['/'].includes(pathname);
  return isSpinner || isInvite;
};

const Layouts = (props: { children?: React.ReactNode }) => {
  const theme = useTheme();
  const matchUpMd = false; // useMediaQuery(theme.breakpoints.up('md'));
  const { isBgTransparent, isBackdropFilter } =
    useHeaderTransparentBackground();
  // const isOffsetHeader = useOffSetTop((window.innerWidth * 24) / 100);
  // const [leftDrawerOpened, setLeftDrawerOpened] = useState<boolean>(true);
  const isLogin = useRecoilValue(isLoginState);
  const isShowDLGuide = useRecoilValue(isShowHomeGuide);
  const location = useLocation();
  const homeTabValue = useRecoilValue(homeTab);
  useHomeTabScrollToTop();

  const isMobile = !matchUpMd;
  const isHideMobleFooter =
    isMobile && NO_FOOTER_PATHS.includes(location.pathname);
  const isHideMobileHeader =
    isMobile && NO_HEADER_PATHS.includes(location.pathname);
  const isHideAppFooterBar = !APP_BAR_PATHS.includes(location.pathname);
  // 首页显示下载引导
  const isShowDLGuideT =
    isLogin &&
    isMobile &&
    homeTabValue === HomeTab.Home &&
    DOWNLOAD_GUIDE_PATHS.includes(location.pathname) &&
    !isShowDLGuide;

  const heanderHeight = isMobile ? mobileHeaderbarHeight : webHeaderbarHeight;
  // const footerRealHeight = isMobile ? h5FooterHeight : footerHeight;
  return (
    <Box
      sx={{
        display: 'flex',
        bgcolor: 'background.default',
        maxWidth: globalMaxWidth,
        mx: 'auto',
        // backgroundSize: '100% auto',
        // backgroundRepeat: 'no-repeat',
        // backgroundPosition: 'center top',
      }}
    >
      {/* {isMobile ? <HoverMenu /> : null} */}
      {/* <MobileFooterButtons /> */}
      {/* 修订底部菜单没了导致不弹窗 */}
      <IOSDownLoadDrawer />
      <CssBaseline />
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          display: isHideMobileHeader ? 'none' : 'flex',
          bgcolor: {
            // xs: 'background.default',
            xs: isBgTransparent ? 'transparent' : 'background.default',
          },
          backdropFilter: isBackdropFilter ? 'blur(6px)' : 'none',
          // bgcolor: bgcolorChangeOffset
          //   ? 'background.default'
          //   : 'rgba(8, 1, 15, 0.1)',
          pl: { xs: 0 },
          left: '50%',
          transform: 'translateX(-50%)',
          maxWidth: globalMaxWidth,
          width: '100%',

          height: mobileHeaderbarHeight,
          justifyContent: 'center',
          transition: theme.transitions.create('width'),
        }}
      >
        <Box sx={{ px: { xs: 2 } }}>
          <Box
            sx={{
              maxWidth: globalMaxWidth,
              width: '100%',
              mx: 'auto',
            }}
          >
            <Toolbar
              sx={{
                px: { xs: 0 },
                zIndex: 200,
                minHeight: `${mobileHeaderbarHeight}px !important`,
              }}
            >
              <Header />
            </Toolbar>
            {isShowDLGuideT ? <HomeHeaderGuide /> : null}
          </Box>
        </Box>
      </AppBar>

      {/* <Sidebar
        drawerOpen={leftDrawerOpened}
        drawerToggle={() => setLeftDrawerOpened((prev) => !prev)}
      /> */}

      {isHideAppFooterBar ? null : <MoblieAppbar />}

      <HeartbeatMsg showLevel={MessageShowLevel.全局轮播} />

      <Main
        sx={{
          px: {
            xs:
              isFullScreen(location.pathname, homeTabValue) ||
              homeTabValue === HomeTab.Vip ||
              MOBILE_FULL_SCREEN_PATHS.includes(location.pathname) ||
              location.pathname.indexOf('/game/info') !== -1
                ? 0
                : 2, //修订抽奖也没拉伸
          },
          pt: {
            xs:
              isHideMobileHeader ||
              isFullScreen(location.pathname, homeTabValue) ||
              MOBILE_FULL_SCREEN_PATHS.includes(location.pathname)
                ? 0
                : `${heanderHeight}px`,
          },
          pb: {
            xs: isHideMobleFooter
              ? 4
              : isHideAppFooterBar
              ? `${0}px`
              : `${footerbarHeight}px`,
          },
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          sx={{
            maxWidth: '1260px',
            margin: matchUpMd ? '0 auto 40px' : '0 auto',
          }}
        >
          <Box flex="1 0 auto">
            <Outlet />
          </Box>
        </Box>
      </Main>
      <MoneyDialog />
    </Box>
  );
};

export default Layouts;
