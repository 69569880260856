import usePlatform from '@/hooks/usePlatform';
import { IGameItem } from '@/types/config';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import { Box, Stack, Typography } from '@mui/material';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { IGame } from '../../types';
import ImageWithLoading from '../image';

interface Props {
  data: IGame;
  // height: number;
}

const SearchGameCard: React.FC<Props> = (props: Props) => {
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement | null>(null);

  return (
    <Box ref={ref} id="123">
      <Box
        onClick={() => {
          navigate(`/game/info?slug=${props.data.slug}`);
        }}
        sx={{
          transition: 'all 0.3s ease',
          cursor: 'pointer',
          position: 'relative',
          overflow: 'hidden',
          borderRadius: '8px',
        }}
      >
        <ImageWithLoading
          // width="100%"
          // height={height}
          type="game"
          aspectRatio="53/70"
          src={props.data.image_url}
          alt={props.data.name}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            position: 'absolute',
            background: 'linear-gradient(360deg, #B304FE 0%, #0CC0F7 100%)',
            opacity: 0,
            '&:hover': {
              opacity: 1,
            },
          }}
        >
          <Stack
            spacing={4}
            height="100%"
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: 600,
                px: 2,
                width: 188,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                textAlign: 'center',
                whiteSpace: 'nowrap',
              }}
            >
              {props.data.name}
            </Typography>
            <Stack alignItems="center" spacing={2}>
              <PlayArrowRoundedIcon sx={{ width: 72, height: 72 }} />
              <Typography
                variant="body1"
                sx={{
                  px: 2,
                  width: 188,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                }}
              >
                {props.data?.provider?.name}
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default SearchGameCard;

export const SearchGameCard2 = (props: { data: IGameItem }) => {
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement | null>(null);
  // const [height, setHeight] = useState<number>(0);
  const { getPlatformTitle } = usePlatform();

  // useEffect(() => {
  //   const handleResize = () => {
  //     if (ref.current) {
  //       setHeight((+ref.current.clientWidth * 4) / 3);
  //     }
  //   };

  //   handleResize();
  //   window.addEventListener('resize', handleResize);
  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, []);

  return (
    <Box ref={ref} id="123">
      <Box
        onClick={() => {
          navigate(`/game/info?id=${props.data.id}`);
        }}
        sx={{
          transition: 'all 0.3s ease',
          cursor: 'pointer',
          position: 'relative',
          overflow: 'hidden',
          borderRadius: '8px',
        }}
      >
        <ImageWithLoading
          // width="100%"
          // height={height}
          aspectRatio="53/70"
          type="game"
          src={props.data.pic}
          alt={props.data.game_name}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            position: 'absolute',
            background: 'linear-gradient(360deg, #B304FE 0%, #0CC0F7 100%)',
            opacity: 0,
            '&:hover': {
              opacity: 1,
            },
          }}
        >
          <Stack
            spacing={1}
            height="100%"
            alignItems="center"
            justifyContent="center"
          >
            <Box
              sx={{
                pt: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'hidden',
                maxWidth: '100%',
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: 600,
                  px: { xs: 0.5, md: 1 },
                  width: '100%',
                  maxWidth: '100%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                }}
              >
                {props.data.game_name}
              </Typography>
            </Box>
            <Stack
              alignItems="center"
              spacing={{
                xs: 1,
                md: 2,
              }}
              sx={{
                overflow: 'hidden',
                maxWidth: '100%',
                minWidth: '100%',
              }}
            >
              <PlayArrowRoundedIcon
                sx={{
                  minWidth: '50%',
                  width: '50%',
                  height: '50%',
                }}
              />
              <Typography
                variant="body1"
                sx={{
                  px: { xs: 0.5, md: 1 },
                  width: '100%',
                  maxWidth: '100%',
                  minWidth: '100%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                }}
              >
                {getPlatformTitle(props.data?.platform_id)}
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};
