import { useRefreshActivity } from '@/hooks/useActivity';
import useAuth from '@/hooks/useAuth';
import { homeTab, inviteOpenAndWinTabState } from '@/stores/config';
import { ActivityType, HomeTab } from '@/types';
import { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import 'swiper/css';
import {
  InvitePageContainer,
  InviteTabButtons,
  TabInvite,
  TabLog,
  TabStatistics,
} from './components';

interface Props {}

const TABS = ['Invite', 'Statistics', 'Log'];

const TabContent = ({ tab, activeTab }: { tab: string; activeTab: string }) => {
  if (tab === 'Invite') {
    return <TabInvite />;
  } else if (tab === 'Statistics') {
    return <TabStatistics />;
  }
  return <TabLog />;
};

const CommissionsPage: React.FC<Props> = (props: Props) => {
  const [tab, setTab] = useState<string>(TABS[0]);
  const { refresh } = useRefreshActivity(ActivityType.邀新活动);
  const homeTabValue = useRecoilValue(homeTab);
  const { isLogin, openLogin } = useAuth();
  const setInviteOpenAndWinTab = useSetRecoilState(inviteOpenAndWinTabState);

  useEffect(() => {
    if (homeTabValue === HomeTab.Invite) {
      setTab(TABS[0]);
    }

    // if not in invite tab, set open tab
    if (homeTabValue !== HomeTab.Invite) {
      setInviteOpenAndWinTab('Open');
    }
  }, [homeTabValue]);

  const handleChange = (tab: string, index: number) => {
    if (tab !== 'Invite' && !isLogin) {
      openLogin();
      return;
    }

    setTab(tab);

    if (tab === 'Invite') {
      refresh();
    }
  };

  return (
    <InvitePageContainer>
      <InviteTabButtons tab={tab} tabs={TABS} onClickTab={handleChange} />

      <TabContent tab={tab} activeTab={tab} />
    </InvitePageContainer>
  );
};

export default CommissionsPage;
