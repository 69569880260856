const AudioContext =
  window.AudioContext ||
  (window as any).webkitAudioContext ||
  (window as any).mozAudioContext ||
  (window as any).msAudioContext;

// 创建音频上下文
const audioContext = new AudioContext();

/**
 * 使用 AudioContext 播放 mp3
 * @param url 音频地址
 */
export function playAudio(url: string) {
  fetch(url)
    .then((response) => response.arrayBuffer())
    .then((arrayBuffer) => {
      // 解码获取的二进制数据
      audioContext.decodeAudioData(
        arrayBuffer,
        function (audioBuffer) {
          // 创建音频源
          const audioSource = audioContext.createBufferSource();
          audioSource.buffer = audioBuffer;

          // 改变播放速度
          audioSource.playbackRate.value = 1.2;

          // 创建一个 GainNode
          const gainNode = audioContext.createGain();
          // 将 BufferSourceNode 连接到 GainNode
          audioSource.connect(gainNode);

          // 连接音频源到输出
          gainNode.connect(audioContext.destination);
          // 设置音量 10
          gainNode.gain.value = 5;

          // 播放音频
          audioSource.start();
        },
        function (err) {
          console.error('解码失败', err);
        },
      );
    })
    .catch((error) => {
      console.error('请求失败', error);
    });
}
