import { Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

interface Props {
  name: string;
  width?: number;
}

const GameFont: React.FC<Props> = (props: Props) => {
  const ref = useRef<HTMLSpanElement>(null);
  const [width, setWidth] = useState<number>(props.width ?? 70);
  useEffect(() => {
    if (props.width) {
      return;
    }

    const onResize = () => {
      if (props.width) {
        return;
      }

      if (ref.current) {
        setWidth(ref.current.clientWidth);
      }
    };
    onResize();
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [props.width]);

  const realWidth = props.width ?? width;

  return (
    <Typography
      ref={ref}
      variant="subtitle1"
      className="br-game-font"
      sx={{
        fontWeight: '400',
        px: 1,
        overflow: 'hidden',
        // display: '-webkit-box',
        // lineClamp: '2',
        width: '100%',
        textOverflow: 'ellipsis',
        textAlign: 'center',
        fontSize: Math.ceil((14 * realWidth) / 106) + 'px',
        lineHeight: Math.ceil((14 * realWidth) / 106) + 'px',
        textShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
      }}
    >
      {props.name}
    </Typography>
  );
};

export default GameFont;
