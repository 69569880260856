import { globalMaxWidth } from '@/theme';
import { useEffect, useState } from 'react';

export const useWidth = () => {
  const [width, setWidth] = useState<number>(0);

  useEffect(() => {
    const handleResize = () => {
      const maxWidth = Math.min(window.innerWidth, globalMaxWidth);
      const width = maxWidth - 16 - 16 - 6 * 5;
      setWidth(Math.round(width / 4.5));
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return width;
};
