import { Request } from '../utils';
// const host = "https://game.edestrelaclube.link";
const host = '';

export async function getGameList(params: {
  page: number;
  limit: number;
  base_game_category_slugs?: string;
  game_category_slugs?: string;
  order?: string;
  search_keyword?: string;
}) {
  return Request.get(`${host}/api/games/search`, { params });
}

export function getGameInfo(slug: string) {
  return Request.get(`${host}/api/games/${slug}`);
}

export function getGamePlayInfo(slug: string) {
  return Request.post(`${host}/api/games/${slug}/play`, {
    game_slug: slug,
    selected_currency_type: 'BRL',
    type: 'play',
  });
}

export function getGameDemoInfo(slug: string) {
  return Request.post(`${host}/api/games/${slug}/demo`, {
    game_slug: slug,
    selected_currency_type: 'BRL',
    type: 'demo',
  });
}
