import { HeartbeatMessageDto } from '@/types';
import { atom } from 'recoil';

export const msgState = atom<{
  message: HeartbeatMessageDto | null;
}>({
  key: 'msgState',
  default: {
    message: null,
    // message: {
    //   contents: {
    //     enUS: '939*** won R$1650.00 in Dragon Tiger Luck', //站内信消息内容语言包-英文
    //     ptBR: '1***42 recebeu R$1000000', //站内信消息内容语言包-葡文
    //     zhCN: '1***42 收到 R$1000000', //站内信消息内容语言包-中文
    //   }, //站内信消息内容语言包
    //   pic: 'https://www.br-baby.com/api/uploads/avatar.png', //展示图片
    //   routePath: '/commissions', //跳转路由
    //   receiverType: 5,
    //   showLevel: 2, //展示等级：1、首页轮播；2、全局轮播；3、当前页弹窗展示
    // },
  },
});
