import { Backdrop, Box, CircularProgress } from '@mui/material';
import { useState } from 'react';
import ICON_SLOT1 from '../../assets/icons/icon_slot_1.png';
import IMAGE_BG from '../../assets/images/pic-spinner1.png';
import IMAGE_POINTER from '../../assets/images/pic-spinner2.png';
import { SPINNER_PRIZES } from '../../constants';
import services from '../../services';
import { IMyXp } from '../../types/user';
import { handleRequestError } from '../../utils/request';
import { SnackbarUtils } from '../snackbar/SnackbarProvider';
// import ICON_SLOT2 from "../../assets/icons/icon_slot_2.png";

import IMG_REWARDS_1_1 from '../../assets/rewards/icon_rewards_1-1.png';
import IMG_REWARDS_1_11 from '../../assets/rewards/icon_rewards_1-11.png';
import IMG_REWARDS_1_2 from '../../assets/rewards/icon_rewards_1-2.png';
import IMG_REWARDS_1_3 from '../../assets/rewards/icon_rewards_1-3.png';
import IMG_REWARDS_1_9 from '../../assets/rewards/icon_rewards_1-9.png';
import IMG_REWARDS_10_1 from '../../assets/rewards/icon_rewards_10-1.png';
import IMG_REWARDS_10_2 from '../../assets/rewards/icon_rewards_10-2.png';
import IMG_REWARDS_10_3 from '../../assets/rewards/icon_rewards_10-3.png';
import IMG_REWARDS_10 from '../../assets/rewards/icon_rewards_10.png';
import IMG_REWARDS_11 from '../../assets/rewards/icon_rewards_11.png';
import IMG_REWARDS_5 from '../../assets/rewards/icon_rewards_5.png';
import IMG_REWARDS_6 from '../../assets/rewards/icon_rewards_6.png';
import IMG_REWARDS_7 from '../../assets/rewards/icon_rewards_7.png';
import IMG_REWARDS_8 from '../../assets/rewards/icon_rewards_8.png';

const REWARDS_MAP: any = {
  'icon_rewards_1-1.png': IMG_REWARDS_1_1,
  'icon_rewards_1-2.png': IMG_REWARDS_1_2,
  'icon_rewards_1-3.png': IMG_REWARDS_1_3,
  'icon_rewards_1-9.png': IMG_REWARDS_1_9,
  'icon_rewards_1-11.png': IMG_REWARDS_1_11,
  'icon_rewards_5.png': IMG_REWARDS_5,
  'icon_rewards_6.png': IMG_REWARDS_6,
  'icon_rewards_7.png': IMG_REWARDS_7,
  'icon_rewards_8.png': IMG_REWARDS_8,
  'icon_rewards_10-1.png': IMG_REWARDS_10_1,
  'icon_rewards_10-2.png': IMG_REWARDS_10_2,
  'icon_rewards_10-3.png': IMG_REWARDS_10_3,
  'icon_rewards_10.png': IMG_REWARDS_10,
  'icon_rewards_11.png': IMG_REWARDS_11,
};

interface Props {
  boxId: number;
  myXp: IMyXp;
}

const DEG_PRE_24 = 15;

const Spinner: React.FC<Props> = (props: Props) => {
  const [deg, setDeg] = useState<number>(0);
  const [opening, setOpening] = useState<boolean>(false);

  const handleClickStart = async () => {
    const isSuccess = await handleOpenBox();
    if (isSuccess) {
      isSuccess && setDeg((prev) => prev + 1000);
    } else {
      SnackbarUtils.info('测试转盘效果');
      setDeg((prev) => {
        if (prev.toString().includes('.5')) {
          return prev + (360 * 3 + 15 * 3);
        } else {
          return prev + (360 * 3 + 15 * 3 + 7.5);
        }
      });
    }
  };

  const handleOpenBox = async () => {
    if (opening) {
      return false;
    }
    try {
      setOpening(true);
      const { data } = await services.auth.openBox({
        box_source: 1,
        rank_id: props.myXp.rank_id,
        box_id: props.boxId,
      });
      console.log(data);
      return true;
    } catch (error: any) {
      console.log(error);
      if (error.response) {
        handleRequestError(error.response);
      }
      if (error?.response?.status === 504) {
        SnackbarUtils.error(error.response.statusText);
      }
      return false;
    } finally {
      setOpening(false);
    }
  };

  const prizes = SPINNER_PRIZES[0].unranked;

  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        sx={{
          transform: `rotate(${deg}deg)`,
          transition: 'all 3s ease-out',
          position: 'relative',
        }}
      >
        {prizes.map((prize, index) => {
          return (
            <Box
              key={index}
              sx={{
                width: '12.74%',
                height: '50%',
                position: 'absolute',
                top: 0,
                left: 'calc(50% - 6.37%)',
                transform: `rotate(${DEG_PRE_24 * index + 7.5}deg)`,
                transformOrigin: 'bottom',
                pt: '10%',
              }}
            >
              <img
                src={prize.image ? REWARDS_MAP[prize.image] : ICON_SLOT1}
                alt=""
                style={{ width: '50%', margin: '0 auto' }}
              />
            </Box>
          );
        })}
        <img src={IMAGE_BG} alt="" />
      </Box>

      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
        onClick={handleClickStart}
      >
        <img src={IMAGE_POINTER} alt="" />
      </Box>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={opening}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

export default Spinner;
