import IMAGE_USER from '@/assets/buttons/user.png';
import useLang from '@/hooks/useLang';
import useResponsive from '@/hooks/useResponsive';
import { ScrollMessageDto } from '@/types';
import { Box, Stack, Typography } from '@mui/material';
import { useEffect, useRef } from 'react';

const unit = 3;

let timeout1: NodeJS.Timeout | null = null;
let timeout2: NodeJS.Timeout | null = null;

const handleScroll1 = (
  scroll: HTMLElement,
  times: number,
  time: number,
  totalWidth: number,
  windowWidth: number,
) => {
  if (timeout1) {
    clearTimeout(timeout1);
  }
  if (timeout2) {
    clearTimeout(timeout2);
  }

  scroll.style.transitionDuration = times + 's';
  scroll.style.transform = `translateX(-${totalWidth + windowWidth}px)`;

  timeout1 = setTimeout(() => {
    scroll.style.transitionDuration = '0s';
    scroll.style.transform = `translateX(0px)`;
  }, times * 1000);

  timeout2 = setTimeout(
    () => {
      handleScroll1(scroll, times, time, totalWidth, windowWidth);
    },
    (2 * times - 2 * time) * 1000,
  );
};

let timeout3: NodeJS.Timeout | null = null;
let timeout4: NodeJS.Timeout | null = null;

const handleScroll2 = (
  scroll: HTMLElement,
  times: number,
  time: number,
  totalWidth: number,
  windowWidth: number,
) => {
  if (timeout3) {
    clearTimeout(timeout3);
  }
  if (timeout4) {
    clearTimeout(timeout4);
  }

  scroll.style.transitionDuration = times + 's';
  scroll.style.transform = `translateX(-${totalWidth + windowWidth}px)`;

  timeout3 = setTimeout(() => {
    scroll.style.transitionDuration = '0s';
    scroll.style.transform = `translateX(0px)`;
  }, times * 1000);

  timeout4 = setTimeout(
    () => {
      handleScroll2(scroll, times, time, totalWidth, windowWidth);
    },
    (2 * times - 2 * time) * 1000,
  );
};

const startScroll = (
  list: Array<ScrollMessageDto>,
  isMobile: boolean,
  scroll: HTMLElement,
  scroll2: HTMLElement,
  windowWidth: number,
) => {
  // const scroll = document.getElementById('scroll_message_1');
  // const scroll2 = document.getElementById('scroll_message_2');
  if (scroll && scroll2) {
    const totalWidth = scroll.scrollWidth;
    const times = list.length * unit;
    const speed = (totalWidth + windowWidth) / times;
    const time = windowWidth / speed;

    handleScroll1(scroll, times, time, totalWidth, windowWidth);

    setTimeout(
      () => {
        handleScroll2(scroll2, times, time, totalWidth, windowWidth);
      },
      (times - time) * 1000,
    );
  }
};

export const ScrollMessage = (props: {
  list: Array<ScrollMessageDto>;
  delay?: number;
}) => {
  const isMobile = useResponsive('down', 'md');
  // const left = isMobile ? window.innerWidth : 414;
  const scrollRef = useRef<HTMLDivElement>(null);
  const scroolRef2 = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (props.list.length > 0 && scrollRef.current && scroolRef2.current) {
      const parentWidth = scrollRef.current.parentElement?.clientWidth;
      scrollRef.current.style.left = `${parentWidth}px`;
      scroolRef2.current.style.left = `${parentWidth}px`;

      if (props.delay) {
        setTimeout(() => {
          startScroll(
            props.list,
            isMobile,
            scrollRef.current!,
            scroolRef2.current!,
            parentWidth!,
          );
        }, props.delay);
      } else {
        startScroll(
          props.list,
          isMobile,
          scrollRef.current,
          scroolRef2.current,
          parentWidth!,
        );
      }
    }
  }, [props.list, props.delay]);

  return (
    <Box
      sx={{
        px: 2,
        pt: 2,
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <Box width="100%" height="36px"></Box>
      <Box
        id="scroll_message_1"
        ref={scrollRef}
        sx={{
          whiteSpace: 'nowrap',
          transition: 'all',
          transitionTimingFunction: 'linear',
          position: 'absolute',
          // left: `${left}px`,
          left: '1000px',
          top: '16px',
        }}
      >
        {props.list.map((item, index) => {
          return (
            <ScrollMessageItem
              key={`
              ${index}-${item.userId}-${item.receivedAmt}
              `}
              item={item}
            />
          );
        })}
      </Box>
      <Box
        id="scroll_message_2"
        ref={scroolRef2}
        sx={{
          whiteSpace: 'nowrap',
          transition: 'all',
          transitionTimingFunction: 'linear',
          position: 'absolute',
          // left: `${left}px`,
          left: '1000px',
          top: '16px',
        }}
      >
        {props.list.map((item, index) => {
          return (
            <ScrollMessageItem
              key={`
              ${index}-${item.userId}-${item.receivedAmt}
              `}
              item={item}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export const ScrollMessageItem = (props: {
  item: ScrollMessageDto;
  background?: string;
}) => {
  const { getValueByLanguage } = useLang();
  return (
    <Box
      mr={1}
      sx={{
        background:
          props.background ??
          'linear-gradient(90deg, rgba(255, 255, 255, 0.56) 0%, rgba(255, 255, 255, 0.00) 100%)',
        borderRadius: '20px 0px 0px 20px',
        p: 0.5,
        pr: 2,
        display: 'inline-block',
      }}
    >
      <Stack direction={'row'} spacing={0.5} alignItems={'center'}>
        <Box
          sx={{
            width: 28,
            height: 28,
            borderRadius: '50%',
            overflow: 'hidden',
            background: '#323D4D',
          }}
        >
          <img
            src={props.item.avatar ?? IMAGE_USER}
            alt=""
            width={28}
            height={28}
          />
        </Box>
        <Typography>{getValueByLanguage(props.item.desc)}</Typography>
      </Stack>
    </Box>
  );
};
