// import BG_WEB from '@/assets/invitebox/bg-web.png';
import BG from '@/assets/invitebox/bg.png';
import { mobileHeaderbarHeight } from '@/theme';
import { Box } from '@mui/material';
import React, { FC } from 'react';

interface Props {
  children: React.ReactNode;
}

export const InvitePageContainer: FC<Props> = (props) => {
  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        backgroundImage: {
          xs: `url(${BG})`,
        },
        backgroundPosition: 'center top',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% auto',
        // minHeight: `100vh`,
        position: 'relative',
        // overflowY: 'auto',
        mb: 0,
        mx: 'auto',
        pt: `${mobileHeaderbarHeight}px`,
      }}
    >
      {props.children}
    </Box>
  );
};
