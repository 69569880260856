import { StorageKeys } from '@/constants/keys';
import Fingerprint2 from 'fingerprintjs2';

export const getFingerprintReg = async (ip:string,time:string) => {
  // PC端不唤起，也不延时
  let deviceprint='';
  Fingerprint2.get(
    {
      // preprocessor: null,
      audio: {
        timeout: 1000,
        // On iOS 11, audio context can only be used in response to user interaction.
        // We require users to explicitly enable audio fingerprinting on iOS 11.
        // See https://stackoverflow.com/questions/46363048/onaudioprocess-not-called-on-ios11#46534088
        excludeIOS11: true,
      },
      fonts: {
        swfContainerId: 'fingerprintjs2',
        swfPath: 'flash/compiled/FontList.swf',
        userDefinedFonts: [],
        extendedJsFonts: true,
      },
      screen: {
        // To ensure consistent fingerprints when users rotate their mobile devices
        detectScreenOrientation: true,
      },
      plugins: {
        sortPluginsFor: [/palemoon/i],
        excludeIE: false,
      },
      extraComponents: [
        {
          key: 'rememberIP',
          getData:function (done, options) {
              done(ip || '');
          }
        },{
          key: 'requestTime',
          getData:function (done, options) {
              done(time || '');
          }
        }
      ],
      excludes: {
        // Unreliable on Windows, see https://github.com/Valve/fingerprintjs2/issues/375
        enumerateDevices: true,
        // devicePixelRatio depends on browser zoom, and it's impossible to detect browser zoom
        pixelRatio: true,
        // DNT depends on incognito mode for some browsers (Chrome) and it's impossible to detect incognito mode
        doNotTrack: true,
        // uses js fonts already
        fontsFlash: true,

        // #### Browser independent components

        userAgent: true,
        //no (most of the time)
        language: true,
        // no (but not supported by IE)
        hardwareConcurrency: true,

        sessionStorage: true,
        localStorage: true,
        indexedDb: true,
        addBehavior: true,
        openDatabase: true,
        plugins: true,
        adBlock: true,
        fonts: true,
        audio: true,

        // exclude screen
        screenResolution: true,
        availableScreenResolution: true,

        // (most of the time)
        //'canvas': true,
        //'webgl': true,
        //'fonts',
      },
      NOT_AVAILABLE: 'not available',
      ERROR: 'error',
      EXCLUDED: 'excluded',
    },
    (components) => {
      
      var values = components.map((component) => {
        return component.value;
      });
      deviceprint = Fingerprint2.x64hash128(values.join(''), 31);
      // 缓存 device-id
      if (deviceprint && typeof deviceprint === 'string') {
        deviceprint = `${deviceprint}${ip}${time}`;
        return deviceprint;
      }
      return '';
    },
  );
  return deviceprint;
};

// 新增device-id缓存逻辑 ==> 确保每次请求有device-id
export const getFingerprint2 = async () => {
  const deviceID = localStorage.getItem(StorageKeys.deviceId);
  const rememberIP = localStorage.getItem('rememberIP') || '';
  const requestTime = localStorage.getItem('requestTime') || '';
  const ignModeIp = sessionStorage.getItem('setIncognitoModeIPState');
  let deviceprint: any = deviceID || undefined;
  // PC端不唤起，也不延时
  Fingerprint2.get(
    {
      // preprocessor: null,
      audio: {
        timeout: 1000,
        // On iOS 11, audio context can only be used in response to user interaction.
        // We require users to explicitly enable audio fingerprinting on iOS 11.
        // See https://stackoverflow.com/questions/46363048/onaudioprocess-not-called-on-ios11#46534088
        excludeIOS11: true,
      },
      fonts: {
        swfContainerId: 'fingerprintjs2',
        swfPath: 'flash/compiled/FontList.swf',
        userDefinedFonts: [],
        extendedJsFonts: true,
      },
      screen: {
        // To ensure consistent fingerprints when users rotate their mobile devices
        detectScreenOrientation: true,
      },
      plugins: {
        sortPluginsFor: [/palemoon/i],
        excludeIE: false,
      },
      extraComponents: [
        {
          key: 'rememberIP',
          getData:function (done, options) {
              done(rememberIP || ignModeIp || '');
          }
        },{
          key: 'requestTime',
          getData:function (done, options) {
              done(requestTime || '');
          }
        }
      ],
      excludes: {
        // Unreliable on Windows, see https://github.com/Valve/fingerprintjs2/issues/375
        enumerateDevices: true,
        // devicePixelRatio depends on browser zoom, and it's impossible to detect browser zoom
        pixelRatio: true,
        // DNT depends on incognito mode for some browsers (Chrome) and it's impossible to detect incognito mode
        doNotTrack: true,
        // uses js fonts already
        fontsFlash: true,

        // #### Browser independent components

        userAgent: true,
        //no (most of the time)
        language: true,
        // no (but not supported by IE)
        hardwareConcurrency: true,

        sessionStorage: true,
        localStorage: true,
        indexedDb: true,
        addBehavior: true,
        openDatabase: true,
        plugins: true,
        adBlock: true,
        fonts: true,
        audio: true,

        // exclude screen
        screenResolution: true,
        availableScreenResolution: true,

        // (most of the time)
        //'canvas': true,
        //'webgl': true,
        //'fonts',
      },
      NOT_AVAILABLE: 'not available',
      ERROR: 'error',
      EXCLUDED: 'excluded',
    },
    (components) => {
      
      var values = components.map((component) => {
        return component.value;
      });
      deviceprint = Fingerprint2.x64hash128(values.join(''), 31);
      // 缓存 device-id
      if (deviceprint && typeof deviceprint === 'string') {
        deviceprint = `${deviceprint}${rememberIP}${requestTime}`;
        localStorage.setItem(StorageKeys.deviceId, deviceprint);
      }
    },
  );
};
