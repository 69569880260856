import { ActivityDto, BoxDto } from '@/hooks/useActivity';
import { InviteEventsInfoDto } from '@/types';
import { atom } from 'recoil';

export const inviteActivityState = atom<ActivityDto | null>({
  key: 'inviteActivityState',
  default: null,
});

export const inviteEventsInfoState = atom<InviteEventsInfoDto | null>({
  key: 'inviteEventsInfoState',
  default: null,
});

export const boxesState = atom<Array<BoxDto>>({
  key: 'boxesState',
  default: [],
});

export const inviteWinState = atom<ActivityDto | null>({
  key: 'inviteWinState',
  default: null,
});

export const inviteTimeoutState = atom<boolean>({
  key: 'inviteTimeoutState',
  default: true,
});

export const inviteWinTimeoutState = atom<boolean>({
  key: 'inviteWinTimeoutState',
  default: false,
});
