import { IUserInfo } from '@/types';

import useAuth from '@/hooks/useAuth';
import { Stack } from '@mui/material';
import { useState } from 'react';
import { VipBox } from './VipBox';
import { VipPrivilegeBox } from './VipPrivilege';

export const VipContent = ({
  user,
  noHeader,
}: {
  user: IUserInfo;
  noHeader?: boolean;
}) => {
  const { vip, currentVip } = useAuth();
  const [nowVip, setNowVip] = useState<number>(currentVip - 1);
  const vipData = vip.data?.[nowVip];
  const isLock = user?.vipState === 2;
  return (
    <>
      <VipBox onChangeVip={(v) => setNowVip(v - 1)} />
      <Stack spacing={2} sx={{ px: 2 }}>
        {/* {isLock ? <VipTask vip={currentVip + 1} noHeader={noHeader} /> : null} */}

        {!vipData ? null : (
          <VipPrivilegeBox vipData={vipData} user={user} noHeader={noHeader} />
        )}
      </Stack>
    </>
  );
};
