import useLang from '@/hooks/useLang';
import useResponsive from '@/hooks/useResponsive';
// import ICON_PROVIDER from '../../assets/icons/icon_provider.png';

import { plateformState } from '@/stores/config';
import { IPlatform } from '@/types/config';
import { hideScrollbarY } from '@/utils/cssStyles';
import { Box, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import ImageWithLoading from '../image';

const DEFAULT_IMAGE =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOMAAAByCAMAAACWapaYAAAAM1BMVEUUJDEgLzxDUFpNWWMYJzQbKzg8SVQ3RVAnNkI+TFYxP0pBTlk0Qk0iMT4lMz8qOEQuPEcTijJAAAAE3UlEQVR42uzBAQ0AAADCIPunNsc3YAAAAAAAAHSdHTNQdhSEoShgSICI+v9fu3LBqlt2trozb6bO3pm+EknSHAnYPvpI5mtF3n4s/52c3l7TF1KuiE+HJHtd5stkb8ibr1J/GZ+1kP4/4yNOnX9nnLhqnFdDGJLkDZRHdjxmYyyvwvoHPiltMePSYmgRVVnomDsYWC/51ZLolFP+CcbgNjGZ+DJKVZSakepMKv68DmT3kz1GF9yWZsd8zg1rkyXZguhHGd14YFRvTDrMLKXs8oHFnN4ZoRXS657hmDudGZc98w8xqghKo1IvC5fxbDKKqAgT2Gxzh59Ai6kx0VUHBh6y8TG31tAokOeCPc3ibvdqTtENg4spf8YobX1srCCCGz+WOr1BPYLilzYFLl9ExrSYrMXBtj4PSNdy4yLhWkJUDUp2naJ7jFmGlyRfZpwzWjFXkNae0cxYGVIQRdc0vRhxSySg/ZoVisU5J4TuvcrbNoiLN7cYgxsOcuFar0KAInRYEaZwE3zAVJcRUwu63BjckgSqqvnM6FsGvdWrYfhN4eMzJ+21j6uLHtZRa5fOIzq2y5iwjkjTrPmVO07mzGho4Tq+wZjd74wuf8gohHq1vEpNUq8R3qsjg7txnfejV2yxErxaVrf9qC21Oe1Hs778DKfrjDy8if/eq+05jXonRlVzAL2KtmOE1EHc/BIUMB6TYC4TwwV/IwUsLcYpoM85Qd47mby9xxiGjsIHZ05VPDyf1eAdku0Yadut+3xEl9rdsi33pDUuHKb2LqbLjNJjlGuMhioNedlAcPzlik5/ZBzRkVoNDa/cFi7pyDi2wZ3no+sxumuM+GpZv+kEVqcc9ln49hiVk20ljeuVOPpDbkr6G6NN2Ppy49mRh67y9d8d5NvAm+siei+U3j8BqS8zhj5jeNJvq7nPOD+JceozTk9itH3GZ/0fQHuI+izGX+ybS7KDIBBFgfCR5rv/1T7TaPBGX+LEKkvDiPZOcoIRPBDaYqRrMW5NkOpqzqquGeu1GEcct7GSqxdiLA9qkIhIj3IdRsXTPcHzhhcG6jKMNEElpxqgcqlf/eyQHeW1523NPCswytxktME7NjIYGbJBeVf0AYxpoakKxUhlIbD2OGSJDpTjWamiUR6r2M0xRjlMRTmA0a1lXOrXdvicoD8wglEWIr6qjFGXyOYARrJqJlKOoXttaY+Xc+B5gRGNssitskE5gVFmRTVQsAfNHanEhcOxrR9L+v6O3O49DV4JGMEoc+WlELoYgRF/S4MW2hzACK9XiYn3ukc3k0jwg8AIRpmrKgo9m4RIq6a6kjiOkVc6sXUjr3L2MfLHHv5lRKNsFpsbaoBIU1eZhzFK6tOh4klj/zgmZDQyF8lJQKPMVZ0YM0YixdCuH8goXZl7Jcq9jHX9e+RrlXWiR6PMlTda5ycLRmbsSe6eZh95YMNLvg3e0vO2LNT2lEGjXNpgR5bOGJGv0tDpGDd3RSWr8T4JolHulcoQdS9ZTsjoswBGDjsVGmVj1dSsgci8cM9z5mHC8K5qsWIUyYYx4xFBo9wqFWJ+i/TAN60v+jyM3xraX4OR2Y54P+t3Bul3lux2ZwJvcbbzDmd03yCvOIo3OTN/i/8+/Npfe3BIAAAAACDo/2tvGAAAAAAAAICVALyNPsvRmqPlAAAAAElFTkSuQmCC';

interface GameProviderProps {
  data: IPlatform;
  // width: number;
  // height: number;
  aspectRatio: string;
}
const GameProvider = (props: GameProviderProps) => {
  const isMobile = useResponsive('down', 'md');
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/provider?id=${props.data.id}`);
  };

  return (
    <Box sx={{ cursor: 'pointer' }} onClick={handleClick}>
      <ImageWithLoading
        type="provider"
        width={isMobile ? 128 : 256}
        height={isMobile ? 48 : 96}
        aspectRatio={props.aspectRatio}
        alt={props.data.title}
        src={props.data.pic}
        defaultSrc={DEFAULT_IMAGE}
      />
    </Box>
  );
};

interface Props {}

const GameProviders: React.FC<Props> = (props: Props) => {
  const platform = useRecoilValue(plateformState);
  const { __ } = useLang();

  return (
    <Box>
      <Stack
        mb={1.5}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack height="28px" flexDirection={'row'} alignItems="center">
          <Typography
            variant="subtitle2"
            fontWeight={700}
            className="home-game-list-font"
            sx={{
              textTransform: 'capitalize',
              fontWeight: 700,
              fontSize: 20,
              fontStyle: 'italic',
              lineHeight: 1,
            }}
          >
            {__('Game Providers')}
          </Typography>
        </Stack>
      </Stack>
      <Box
        sx={{
          overflowX: 'scroll',
          width: '100%',
          maxWidth: '100%',
          ...hideScrollbarY,
        }}
      >
        <Stack direction="row" spacing="10px">
          {Object.keys(platform).map((key: string) => {
            const item = platform[+key];
            return (
              <GameProvider aspectRatio="13/3" key={item.id} data={item} />
            );
          })}
        </Stack>
      </Box>
    </Box>
  );
};

export default GameProviders;
