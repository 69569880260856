import { globalMaxWidth } from '@/theme';
import { IGameCategoryImg, IGameItem } from '@/types/config';
import { hideScrollbarX } from '@/utils/cssStyles';
import { Box, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import { GameCard } from './GameList';
import HomeSectionContainer from './SectionContainer';

interface Props {
  title: string;
  desc?: string;
  icon?: IGameCategoryImg;
  bgImg?: IGameCategoryImg;
  list: IGameItem[];
  onClickMore?: () => void;
}

// export const HomeGameListSmall = (props: Props) => {
//   const isMobile = useResponsive('down', 'md');
//   if (isMobile) {
//     return <HomeGameListSmallMobile {...props} />;
//   }
//   return <HomeGameList2Web {...props} />;
// };

export const HomeGameList2Web = (props: Props) => {
  const showList = props.list.slice(0, 10);
  return (
    <HomeSectionContainer
      title={props.title}
      desc={props.desc}
      icon={props?.icon?.square}
      onClickMore={props.onClickMore}
      total={props.list.length}
    >
      <Box mb={2}>
        <Grid container gridRow={1} columns={30} spacing={{ xs: 1.5, sm: 2 }}>
          {showList.map((item, index) => {
            return (
              <Grid item key={item.id} md={3}>
                <GameCard item={item} />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </HomeSectionContainer>
  );
};

export const HomeGameListSmallMobile = (props: Props) => {
  const [width, setWidth] = useState<number>(0);

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = Math.min(window.innerWidth, globalMaxWidth);
      const totalWidth = windowWidth - (16 * 2 + 8 * 4);
      const itemWidth = Math.floor(totalWidth / 4.5);
      setWidth(itemWidth);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Box sx={{ mb: 2.5 }}>
      <HomeSectionContainer
        title={props.title}
        desc={props.desc}
        // icon={props?.icon?.square}
        onClickMore={props.onClickMore}
        total={props.list.length}
      >
        <Box
          sx={{
            ...hideScrollbarX,
            display: 'flex',
          }}
        >
          {props.list.map((item) => {
            return (
              <Box key={item.id} mr={1}>
                <GameCard
                  item={item}
                  width={width}
                  height={(width * 70) / 53}
                />
              </Box>
            );
          })}
        </Box>
      </HomeSectionContainer>
    </Box>
  );
};

// 首页小卡片样式，一行放6个，show_type:1
export const HomeGameListSmall = HomeGameListSmallMobile;
