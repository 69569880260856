import { allGamesMap } from '@/stores/config';
import { IIndexCategory } from '@/types/config';
import { hideScrollbarX } from '@/utils/cssStyles';
import { Box } from '@mui/material';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { GameCard } from './GameList';
import HomeSectionContainer from './SectionContainer';

interface Props {
  list: IIndexCategory[];
}

export const HomeHotGameList = (props: Props) => {
  const navigate = useNavigate();

  // useEffect(() => {
  //   const handleResize = () => {
  //     const windowWidth = Math.min(window.innerWidth, globalMaxWidth);
  //     const totalWidth = windowWidth - (16 * 2 + 12 * 2);
  //     const itemWidth = Math.floor(totalWidth / 2.6);
  //     setWidth(itemWidth);
  //   };

  //   handleResize();
  //   window.addEventListener('resize', handleResize);
  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, []);

  const handleClickMore = (title: string) => () => {
    navigate('/category?type=' + title);
  };

  if (!props.list || props.list.length === 0) {
    return null;
  }

  return (
    <>
      {props.list.map((item, index) => {
        return (
          <Box key={`${index}-${item.title}`} mb={2.5}>
            <HomeSectionContainer
              title={item.title}
              total={item.ids.length}
              onClickMore={handleClickMore(item.title)}
            >
              <HotGameList ids={item.ids} />
            </HomeSectionContainer>
          </Box>
        );
      })}
    </>
  );
};

const HotGameList = (props: { ids: number[] }) => {
  const allGamesMapValue = useRecoilValue(allGamesMap);

  const list = useMemo(() => {
    return props.ids.map((id) => {
      return allGamesMapValue[id];
    });
  }, [allGamesMapValue, props.ids]);

  const getIdxFixedZero = (idx: number) => {
    return idx < 10 ? `0${idx}` : idx;
  };

  return (
    <Box
      sx={{
        ...hideScrollbarX,
        display: 'flex',
      }}
    >
      {list.map((item, idx) => {
        return (
          <Box key={item.id} mr={1} position="relative">
            <Box
              sx={{
                position: 'absolute',
                top: '4px',
                left: '4px',
                zIndex: 1,
                bgcolor: 'white',
                color: '#333333',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 999,
                fontSize: 8,
                fontWeight: 700,
                width: '20px',
                height: '20px',
              }}
            >
              {getIdxFixedZero(idx + 1)}
            </Box>
            <GameCard
              aspectRatio={'136/104'}
              item={item}
              width={136}
              noTitle
              isHorizontal
              height={104}
            />
          </Box>
        );
      })}
    </Box>
  );
};
