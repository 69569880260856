import IMAGE_TIMEOUT from '@/assets/images/network-timeout.png';
import { SnackbarUtils } from '@/components/snackbar/SnackbarProvider';
import { ActivityDto, BoxStatus } from '@/hooks/useActivity';
import useAuth from '@/hooks/useAuth';
import useBoolean from '@/hooks/useBoolean';
import useLang from '@/hooks/useLang';
import { MoneyRewardDialog } from '@/pages/Promotions/components/money-reward-dialog';
import services from '@/services';
import { winBoxDetailState } from '@/stores/config';
import { ActivityType, LanguageType } from '@/types';
import { LoadingButton } from '@mui/lab';
import { Backdrop, Box, CircularProgress, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';
import { WinList } from './WinList';

interface Props {}

const generateBoxesByDetail = (detail: ActivityDto | null) => {
  if (!detail) {
    return [];
  }

  return (
    detail?.rules?.map((rule) => {
      return {
        money: rule.activityPrizeList[0].prizeValue.value,
        targetInvite: Number(rule.rules[0].value),
        status: Number(rule.ruleState) as BoxStatus,
        ruleKey: rule.ruleKey,
      };
    }) ?? []
  );
};

export const WinCommission = (props: Props) => {
  const { language } = useLang();
  const { getWallet, getUserInfo } = useAuth();
  const [detail, setDetail] = useRecoilState(winBoxDetailState);
  const [isLoading, setIsLoading] = useState(true);
  const [isTimeout, setIsTimeout] = useState(false);
  const [opening, setOpening] = useBoolean();
  const [isRefresh, setIsRefresh] = useBoolean();
  const [reward, setReward] = useState<{
    title?: string;
    pic?: string;
    num?: number;
    open: boolean;
  }>({
    open: false,
  });

  const list = useMemo(() => {
    return generateBoxesByDetail(detail);
  }, [detail]);

  const fetchData = async () => {
    const res = await services.config.getActivityListReturnError(
      ActivityType.返佣活动,
    );

    if (res?.code === 'ECONNABORTED' || res?.code === 'ERR_NETWORK') {
      setIsTimeout(true);
    } else {
      setIsTimeout(false);
    }

    if (Array.isArray(res.list) && res.list.length) {
      let detail = res.list[0] as ActivityDto;
      setDetail(detail);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleClickOpen = async (ruleKey: number) => {
    if (!detail?.userActivity) {
      SnackbarUtils.info('Network error, please try again later.');
      return;
    }

    setOpening.on();

    const data = await services.config.openBox({
      activityId: detail?.userActivity?.activityId,
      userActivityId: detail?.userActivity?.id,
      // -1 表示全部开启
      ruleKey,
    });

    setOpening.off();
    // 刷新数据
    refresh();
    // 显示奖励
    if (Array.isArray(data) && data?.[0]) {
      const reward = data?.[0];
      setReward({
        title:
          language === LanguageType.PT
            ? reward?.prizeName.ptBR
            : reward?.prizeName.enUS,
        pic: reward?.pic,
        num:
          ruleKey === -1
            ? detail.userActivity?.unReceiveTriggers?.length ?? 0
            : 1,
        open: true,
      });
    }
  };

  const refresh = async () => {
    await fetchData();
    getWallet();
    getUserInfo();
  };

  if (!detail && isTimeout) {
    return (
      <Box
        sx={{
          px: 2,
          pb: 3,
        }}
      >
        <Box maxWidth="280px" mx="auto" textAlign="center" pt={3}>
          <img src={IMAGE_TIMEOUT} alt="" width={280} height={210} />
          <Typography mt={2} sx={{ maxWidth: '280px' }}>
            Tempo limite de acesso, atualize e tente novamente
          </Typography>
          <LoadingButton
            loading={isRefresh}
            variant="contained"
            onClick={async () => {
              setIsRefresh.on();
              await refresh();
              setIsRefresh.off();
            }}
            sx={{
              mt: 3,
              width: '200px',
              height: '48px',
              borderRadius: '999px',
            }}
          >
            Refresh
          </LoadingButton>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        px: 2,
        pb: 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography
        variant="body1"
        fontWeight={500}
        lineHeight="18px"
        fontStyle="italic"
      >
        O valor total da aposta dos usuários que você convida atende às
        condições da tarefa e você receberá o bônus correspondente.
      </Typography>
      <WinList
        current={+(detail?.userActivity?.completeRules?.[0]?.currentValue ?? 0)}
        list={list}
        isLoading={isLoading}
        onOpen={handleClickOpen}
      />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={opening}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <MoneyRewardDialog
        open={reward.open}
        onClose={() => {
          setReward({ open: false });
        }}
        title={reward.title}
        // num={reward.num}
        // pic={reward.pic}
      />
    </Box>
  );
};
