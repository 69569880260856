import { allGamesMap } from '@/stores/config';
import { globalMaxWidth } from '@/theme';
import { IIndexCategory } from '@/types/config';
import { hideScrollbarX } from '@/utils/cssStyles';
import { Box } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { GameCard } from './GameList';
import HomeSectionContainer from './SectionContainer';

interface Props {
  list: IIndexCategory[];
}

export const HomeNewGameList = (props: Props) => {
  const [width, setWidth] = useState<number>(0);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = Math.min(window.innerWidth, globalMaxWidth);
      const totalWidth = windowWidth - (16 * 2 + 8 * 4);
      const itemWidth = Math.floor(totalWidth / 4.5);
      setWidth(itemWidth);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleClickMore = (title: string) => () => {
    navigate('/category?type=' + title);
  };

  if (!props.list || props.list.length === 0) {
    return null;
  }

  return (
    <>
      {props.list.map((item, index) => {
        return (
          <Box key={`${index}-${item.title}`} mb={2.5}>
            <HomeSectionContainer
              title={item.title}
              total={item.ids.length}
              onClickMore={handleClickMore(item.title)}
            >
              <NewGameList
                width={width}
                height={(width * 70) / 53}
                ids={item.ids}
              />
            </HomeSectionContainer>
          </Box>
        );
      })}
    </>
  );
};

const NewGameList = (props: {
  ids: number[];
  width: number;
  height: number;
}) => {
  const allGamesMapValue = useRecoilValue(allGamesMap);

  const list = useMemo(() => {
    return props.ids.map((id) => {
      return allGamesMapValue[id];
    });
  }, [allGamesMapValue, props.ids]);

  return (
    <Box
      sx={{
        ...hideScrollbarX,
        display: 'flex',
      }}
    >
      {list.map((item) => {
        return (
          <Box key={item.id} mr={1}>
            <GameCard item={item} width={props.width} height={props.height} />
          </Box>
        );
      })}
    </Box>
  );
};
